import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonTableList from '../common/common-table-list';
import CommonDetail from '../common/common-detail';
import ProductDetail from './product-detail';
import { initProduct, product } from '../../model/master';
import { api_product } from '../../api/product';
import { isEmpty } from '../../utils/util';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const Product: React.FC = (prop) => {

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [product, setProduct] = useState<product>(initProduct);

    const filterField: any = ['product_code', 'product_name', 'product_name_other', 'vendor_name'];

    const placeholder_txt: string = 'รหัสสินค้า / ชื่อสินค้า / ชื่อสินค้าอื่นๆ / ผู้ขายส่งสินค้า' 

    const columns: ColumnsType<product> = [
        { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code', fixed: 'left', width: '170px' },
        { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name', width: '300px' },
        { title: 'ชื่อสินค้าอื่นๆ', dataIndex: 'product_name_other', key: 'product_name_other', width: '200px' },
        { title: 'ประเภทสินค้า', dataIndex: 'product_type_name', key: 'product_type_name', width: '160px' },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity', width: '130px' },
        { title: 'น้ำหนัก', dataIndex: 'weight', key: 'weight', width: '130px' },
        { title: 'ราคาต้นทุน', dataIndex: 'product_cost', key: 'product_cost', width: '130px' },
        { title: 'ราคาขาย (ปลีก)', dataIndex: 'price_retail', key: 'price_retail', width: '130px' },
        { title: 'ราคาขาย (ส่ง)', dataIndex: 'price_wholesale', key: 'price_wholesale', width: '130px' },
        // { title: 'ผู้ขายส่งสินค้า', dataIndex: 'vendor_name', key: 'vendor_name' },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getProduct = await api_product('LIST', {});
        setData(getProduct);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onShowDetail = async (mode: string, productData?: product) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('เพิ่มสินค้า')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขสินค้า')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดสินค้า')
        }

        setMode(mode);
        let tempData = productData !== undefined && productData !== null ? productData : initProduct;
        if (tempData.id > 0) {
            let getProduct = await api_product('GET', {}, tempData.id);
            setProduct(getProduct);
        } else {
            setProduct(tempData);
        }
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(product));
        if (product.id > 0) {
            operation = 'UPDATE';
            key_id = product.id;
        }

        if (!isEmpty(body['product_code']) && body['product_type'] > 0 && body['product_unit'] > 0 && !isEmpty(body['product_cost']) && !isEmpty(body['price_retail'])) {
            body['product_cost'] = isEmpty(body['product_cost']) ? null : body['product_cost']
            body['price_retail'] = isEmpty(body['price_retail']) ? null : body['price_retail']
            body['price_wholesale'] = isEmpty(body['price_wholesale']) ? null : body['price_wholesale']
            body['price_retail_member'] = isEmpty(body['price_retail_member']) ? null : body['price_retail_member']
            body['price_wholesale_member'] = isEmpty(body['price_wholesale_member']) ? null : body['price_wholesale_member']
            body['init_order_amount'] = isEmpty(body['init_order_amount']) ? null : body['init_order_amount']
            body['point_purchase'] = isEmpty(body['point_purchase']) ? null : body['point_purchase']
            body['overflow_point'] = isEmpty(body['overflow_point']) ? null : body['overflow_point']
            body['limit_quantity'] = isEmpty(body['limit_quantity']) ? null : body['limit_quantity']
            body['point'] = isEmpty(body['point']) ? null : body['point']
            body['vendor'] = body['vendor'].length <= 0 ? "" : JSON.stringify(body['vendor']).replace('[', '').replace(']', '') + ","
            body['remark'] = isEmpty(body['remark']) ? null : body['remark']
            body['vat'] = body['vat'] < 0 ? null : body['vat']
            body['tier_price'] = isEmpty(body['tier_price']) ? "[]" : body['tier_price']

            let res = await api_product(operation, body, key_id);
            if (res === 1) {
                setOpen(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { init(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }

    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_product('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setProduct(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='product' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='product' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='40%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <ProductDetail product={{...product}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default Product;