import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store } from "./store/index";
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faFileExcel, faPlus, faPrint, faEye, faCopy, faPenToSquare, faTrashCan, faXmark, faFloppyDisk, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faUser, faFileExcel, faPlus, faPrint, faEye, faCopy, faPenToSquare, faTrashCan, faXmark, faFloppyDisk, faMagnifyingGlass, faXmarkCircle)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
