import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

type userState = {
    id: number;
    name: string;
    code: string;
    privilege: number;
    privilege_name: string;
    default_page: string;
}

const initialValues:userState = {
    id: 0,
    name: "",
    code: "",
    privilege: 0,
    privilege_name: "",
    default_page: ""
}

const userSlice = createSlice({
    name: "user",
    initialState: initialValues,
    reducers: {
        initailData: (state: userState, action: PayloadAction<void>) => {
            state = initialValues;
        },
        increase: (state: userState, action: PayloadAction<userState>) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.code = action.payload.code;
            state.privilege = action.payload.privilege;
            state.privilege_name = action.payload.privilege_name;
            state.default_page = action.payload.default_page;
        }
    },
    extraReducers: (builder) => {

    }
});

export const { increase, initailData } = userSlice.actions;
export const userSelector = (store: RootState) => store.userReducer;
export default userSlice.reducer;