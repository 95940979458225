import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppstoreOutlined, BookOutlined, SettingOutlined, DollarOutlined, DatabaseOutlined, TeamOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { increase, initailData } from "../store/user/slice";
import { useAppDispatch } from "../store/index";
import { Dropdown, Menu, Modal, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_employee } from '../api/employee';
import { getToken, isEmpty } from '../utils/util';
import { decode as base64_decode } from 'js-base64';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { api_work_shift } from '../api/sale';

const logo = require('../assets/images/logo.png')

const NavMenu: React.FC = (prop) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState('');

    const menu: MenuProps['items'] = [
        { label: 'ขาย', key: 'sale', icon: <DollarOutlined rev={undefined} /> },
        { label: 'จัดการสินค้า', key: 'manage_product', icon: <DatabaseOutlined rev={undefined} />,
            children: [
                { label: 'สินค้า', key: 'product' },
                { label: 'แยกแพค', key: 'separate' },
                { label: 'รวมแพค', key: 'include' },
                { label: 'นับสต๊อก', key: 'check_stock' },
                { label: 'รับเข้า', key: 'good_receive' },
                { label: 'เบิกสินค้าบริการ', key: 'withdraw' },
            ],
        },
        { label: 'จัดการสมาชิก', key: 'manage_member', icon: <TeamOutlined rev={undefined} />, 
            children: [
                { label: 'สมาชิก', key: 'member' },
                { label: 'กลุ่มสมาชิก', key: 'member_group' },
            ],
        },
        { label: 'รายงาน', key: 'report', icon: <BookOutlined rev={undefined} /> },
        { label: 'ตั้งค่า', key: 'config', icon: <SettingOutlined rev={undefined} />,
            children: [
                { label: 'ตั้งค่าทั่วไป', key: 'config_general' },
                { label: 'ตั้งค่าการแยกแพค', key: 'config_separate' },
            ],
        },
        { label: 'อื่นๆ', key: 'other', icon: <AppstoreOutlined rev={undefined} />,
            children: [
                { label: 'ผู้ขายส่งสินค้า', key: 'vendor' },
                { label: 'พนักงาน', key: 'employee' },
                { label: 'ประเภทสินค้า', key: 'product_type' },
                { label: 'หน่วยสินค้า', key: 'product_unit' },
                { label: 'ประเภทการชำระ', key: 'payment_type' },
            ],
        },
    ];

    const items: MenuProps['items'] = [
        { label: 'ออกจากระบบ', key: 'logout', icon: <LogoutOutlined rev={undefined} /> },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        switch (e.key) {
            case 'sale':
                navigate("/sale");
                break;
            case 'product':
                navigate("/product");
                break;
            case 'check_stock':
                navigate("/check/stock");
                break;
            case 'good_receive':
                navigate("/good/receive");
                break;
            case 'separate':
                navigate("/separate");
                break;
            case 'include':
                navigate("/include");
                break;
            case 'config_separate':
                navigate("/config/separate");
                break;
            case 'employee':
                navigate("/employee");
                break;
            case 'vendor':
                navigate("/vendor");
                break;
            case 'withdraw':
                navigate("/withdraw");
                break;
            case 'member':
                navigate("/member");
                break;
            case 'member_group':
                navigate("/member/group");
                break;
            case 'config_general':
                navigate("/config/general");
                break;

            default:
                break;
        }
    };

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onMenuUserClick: MenuProps['onClick'] = async (key) => {
        if (key.key == "logout") {
            checkWorkShift();
        }
    };

    const logout = async () => {
        let device = await getToken();
        let uuid = isEmpty(window.localStorage.getItem("uuid")) ? "" : window.localStorage.getItem("uuid") + "";
        let data = JSON.parse(base64_decode(uuid));
        let item = await api_employee("LOGOUT", {'device': device}, data['id']);
        if (item == 1) {
            window.localStorage.removeItem("uuid");
            window.localStorage.removeItem("p");
            dispatch(initailData());
            navigate(`/login`);
        } else {
            Modal.error({
                ...getConfigModal('ผิดพลาด', 'ไม่สามารถออกจากระบบได้'),
            });
        }
    }

    const checkWorkShift = async () => {
        let token = '';
        await getToken().then((item: string) => {
            token = item;
        });
        let getWorkShift: any = await api_work_shift('GET_BY_CONDITION', {"device": token});
        if (getWorkShift != null && getWorkShift != undefined) {
            if (getWorkShift['id'] > 0) {
                if (getWorkShift['status'] == 1) {
                    Modal.confirm({
                        ...getConfigModal('แจ้งเตือน', 'ยังไม่มีการปิดกะการทำงาน คุณต้องการออกจากระบบหรือไม่ ?'),
                        ...{ async onOk() { logout() }}
                    });
                } else {
                    logout();
                }
            } else {
                logout();
            }
        } else {
            logout();
        }
    }

    useEffect(() => {
        setCurrent(window.location.pathname.replaceAll('/', '_').replace('_', ''));
    }, []);

    return (
        <>
            <img src={logo} alt="logo" className='logo' />
            <div className='nav-box'>
                <Menu theme="dark" onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menu} />
            </div>
            <Dropdown menu={{ items, onClick: onMenuUserClick }} autoAdjustOverflow={false} placement={'bottomRight'}>
                <Space>
                    <FontAwesomeIcon icon="user" className='userIcon' />
                </Space>
            </Dropdown>
        </>
    );
}

export default NavMenu;
