import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { daysTH, isEmpty, monthsTH } from '../../utils/util';
import { filterOption } from '../../utils/select';
import { getDateFromDatePicker } from '../../utils/datepicker';
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { productType, unitType, vat } from '../../model/master';
import { api_product_type, api_product_unit } from '../../api/product';

interface props {
    product: any,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const { Option } = Select;

const WithdrawProduct: React.FC<props> = (prop) => {

    const months = monthsTH();
    const days = daysTH();
    const [data, setData] = useState<any>({});

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameOther, setNameOther] = useState('');
    const [type, setType] = useState(0);
    const [unit, setUnit] = useState(0);
    const [quantity, setQuantity] = useState('');
    const [weight, setWeight] = useState('');
    const [cost, setCost] = useState('');
    const [priceRetail, setPriceRetail] = useState('');
    const [priceWholesale, setPriceWholesale] = useState('');
    const [priceRetailMember, setPriceRetailMember] = useState('');
    const [priceWholesaleMember, setPriceWholesaleMember] = useState('');
    const [vat, setVat] = useState(-1);
    const [lot, setLot] = useState('');
    const [expireDate, setExpireDate] = useState<DateObject>();
    const [remark, setRemark] = useState('');

    const [typeList, setTypeList] = useState<productType[]>([]);
    const [unitList, setUnitList] = useState<unitType[]>([]);
    const [vatList, setVatList] = useState<vat[]>([]);

    const init = async () => {
        setVatList([
            { key: 0, id: -1, name: 'ไม่ระบุ' },
            { key: 1, id: 0, name: 'Vat out' },
            { key: 2, id: 1, name: 'Vat in' }
        ])

        let getProductType = await api_product_type('LIST', {});
        setTypeList(getProductType);

        let getProductUnit = await api_product_unit('LIST', {});
        setUnitList(getProductUnit);
    }

    const setInputData = (object: any) => {
        setCode(object.product_code);
        setName(object.product_name);
        setNameOther(object.product_name_other);
        setType(object.product_type);
        setUnit(object.product_unit);
        setQuantity(object.quantity)
        setWeight(object.weight)
        setCost(object.cost);
        setPriceRetail(object.price_retail);
        setPriceWholesale(object.price_wholesale);
        setPriceRetailMember(object.price_retail_member);
        setPriceWholesaleMember(object.price_wholesale_member);
        setVat(object.vat);
        setLot(object.lot);
        if (!isEmpty(object.expire_date)) {
            setExpireDate(new DateObject(object.expire_date));
        }
        setRemark(object.remark);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }
    
    const onMappingDataBeforeChange = (e: any, name: string) => {
        let object = {
            target: {
                name: name,
                value: typeof(e) == 'number' ? e : getDateFromDatePicker(e)
            }
        }
        onDataChange(object);
    }

    useEffect(() => {
        init();
        setData(prop.product);
        setInputData(prop.product);
    }, []);


    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox'>
                    <div className='InputBox'>
                        <div className='formInput'>
                            <label>รหัสสินค้</label>
                            <Input name="product_code" value={code} placeholder="EM000X" disabled onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อสินค้า</label>
                            <Input name="product_name" value={name} placeholder={'กรุณาป้อนชื่อสินค้า'} disabled onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อสินค้าอื่นๆ</label>
                            <Input name="product_name_other" value={nameOther} placeholder={'กรุณาป้อนชื่อสินค้า'} disabled onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ประเภท</label>
                            <Select value={type} showSearch filterOption={filterOption} disabled onChange={(e) => { onMappingDataBeforeChange(e, 'product_type') }} >
                                <Option key={0} value={0} code={""} disabled>กรุณาเลือกประเภท</Option>
                                {
                                    typeList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>หน่วย</label>
                            <Select value={unit} showSearch filterOption={filterOption} disabled onChange={(e) => { onMappingDataBeforeChange(e, 'product_unit') }} >
                                <Option key={0} value={0} code={""} disabled>กรุณาเลือกหน่วย</Option>
                                {
                                    unitList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>จำนวนสินค้า<span> *</span></label>
                            <Input name="quantity" value={quantity} placeholder={'กรุณาป้อนจำนวนสินค้า'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>น้ำหนักสินค้า<span> *</span></label>
                            <Input name="weight" value={weight} placeholder={'กรุณาป้อนน้ำหนักสินค้า'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาต้นทุน<span> *</span></label>
                            <Input name="cost" value={cost} placeholder={'กรุณาป้อนราคาต้นทุน'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายปลีก<span> *</span></label>
                            <Input name="price_retail" value={priceRetail} placeholder={'กรุณาป้อนราคาขายปลีก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายส่ง<span> *</span></label>
                            <Input name="price_wholesale" value={priceWholesale} placeholder={'กรุณาป้อนราคาขายส่ง'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายปลีกสมาชิก<span> *</span></label>
                            <Input name="price_retail_member" value={priceRetailMember} placeholder={'กรุณาป้อนราคาขายปลีกสมาชิก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายส่งสมาชิก<span> *</span></label>
                            <Input name="price_wholesale_member" value={priceWholesaleMember} placeholder={'กรุณาป้อนราคาขายส่งสมาชิก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>Vat</label>
                            <Select value={vat} showSearch filterOption={filterOption} onChange={(e) => { onMappingDataBeforeChange(e, 'vat') }} >
                                {
                                    vatList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>Lot</label>
                            <Input name="lot" value={lot} placeholder={'กรุณาป้อน Lot'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>วันหมดอายุ</label>
                            <div className='formInputDate'>
                                <DatePicker
                                    className="fromInputDatetime"
                                    months={months}
                                    weekDays={days}
                                    value={expireDate}
                                    onChange={(e) => { onMappingDataBeforeChange(e, 'expire_date') }}
                                    render={<InputIcon/>}
                                    format={"DD/MM/YYYY"}
                                />
                            </div>
                        </div>
                        <div className='formInput'>
                            <label>หมายเหตุ</label>
                            <Input name="remark" value={remark} placeholder={'กรุณาป้อนหมายเหตุ'} onChange={onDataChange} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WithdrawProduct;