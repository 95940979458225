import axios from 'axios';
import { base_url } from './api-config'; 

const getHeaders = (operation: string, key_id?: number) => {
    let header: any = {
        "Content-Type": "application/json",
        "operation": operation
    }
    if (key_id && key_id != undefined && key_id != null) {
        header["key-id"] = key_id
    }

    return { 
        headers: header
    }
}

export const api_member_group = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/member/group`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}

export const api_member = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/member`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}