export interface configSeparate {
    key: number,
    id: number,
    from_product: number,
    to_product: number,
    from_quantity: number,
    to_quantity: number,
    status: number,
    create_date: string,
    from_product_code: string,
    from_product_name: string,
    from_product_unit_name: string,
    to_product_code: string,
    to_product_name: string,
    to_product_unit_name: string,
    is_continue: boolean,
}

export const initConfigSeparate = {
    key: 0,
    id: 0,
    from_product: 0,
    to_product: 0,
    from_quantity: 0,
    to_quantity: 0,
    status: 0,
    create_date: "",
    from_product_code: "",
    from_product_name: "",
    from_product_unit_name: "",
    to_product_code: "",
    to_product_name: "",
    to_product_unit_name: "",
    is_continue: false,
}