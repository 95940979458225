import React, { useState } from 'react';
import ConfigDevice from './device';
import { Button, Modal } from 'antd';
import ConfigShortcut from './shortcut';

const ConfigGeneral: React.FC = (prop) => {

    const [isOpenShortcut, setIsOpenShortcut] = useState(false);

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => {setIsOpenShortcut(false);}}>ยกเลิก</Button>
        </>
    }

    return (
        <>
            <Button className='btn-common' onClick={() => {setIsOpenShortcut(true);}}>ตั้งค่าเมนูลัด</Button>
            <div style={{height: '20px'}}></div>
            <ConfigDevice />

            <Modal
                closeIcon={false}
                centered
                open={isOpenShortcut}
                footer={setFooter()}
                width={'70%'}
                destroyOnClose={true}
            >
                <ConfigShortcut />
            </Modal>
        </>
    )
}

export default ConfigGeneral;