import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Checkbox, Button, Modal  } from 'antd';
import { api_device } from '../../../api/config';
import { device, initDevice } from '../../../model/config-general';
import { json } from 'stream/consumers';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { isEmpty } from '../../../utils/util';

const ConfigDevice: React.FC = (prop) => {

    const [data, setData] = useState<any>([]);
    const [device, setDevice] = useState<device[]>([]);

    const init = async () => {
        let getDevice = await api_device('LIST', {});
        if (getDevice.length > 0) {
            setDevice(getDevice);
        } 
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    useEffect(() => {
        setDevice(data);
    }, [data]);

    const appendDevice = () => {
        let item = JSON.parse(JSON.stringify(device));
        let object = JSON.parse(JSON.stringify(initDevice));
        if (item.length > 0) {
            let key = item[item.length - 1]['key']
            if (!isEmpty(key)) {
                key = key + 1;
                object.key = key;
            }
        }
        item.push(object);
        setData(item);
    }

    const onDeviceChange = (e: any, index: number) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = device;
        object[index][key] = value;

        setData([...object]);
    }

    const onSaveDevice = async (operation: string, body: any, index: number) => {
        let res = 0;
        let key_id = undefined;

        if (operation == 'DELETE') {
            key_id = body['name'];
            if (body['id'] == 0) {
                let item = JSON.parse(JSON.stringify(device));
                item.splice(index, 1);
                setData(item);
                res = 1;
            } else {
                res = await api_device(operation, body, key_id);
            }
        } else {
            if (!isEmpty(body['name']) && !isEmpty(body['device'])) {
                body['id'] = body['name'];
                res = await api_device(operation, body, key_id);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
                });
            }
        }

        if (res === 1) {
            Modal.success({
                ...getConfigModal('สำเร็จ', 'จัดการมูลสำเร็จแล้ว'),
                ...{ onOk() { init(); }}
            });
        } else {
            Modal.error({
                ...getConfigModal('ผิดพลาด', 'จัดการมูลไม่สำเร็จ'),
            });
        }
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <div className='configDeviceContainer'>
                <div className='configBox' style={{marginTop: '20px'}}>
                    <label className='title'>ตั้งค่าอุปกรณ์</label>
                    <Button className='btn-common tool-title' onClick={appendDevice}>+</Button>
                    {
                        device.map((obj, inx) => {
                            return <div className='configDeviceContainerDetail' key={obj.key}>
                                <div className='configDeviceContainerInput'>
                                    <div>
                                        <label>เครื่องที่</label>
                                        <Input value={obj.name} name="name" placeholder="ลำดับอุปกรณ์" onChange={(e: any) => {onDeviceChange(e, inx)}} />
                                    </div>
                                    <div>
                                        <label>IP</label>
                                        <Input value={obj.device} name="device" placeholder="IP" onChange={(e: any) => {onDeviceChange(e, inx)}} />
                                    </div>
                                </div>
                                <div className='configDeviceContainerTool'>
                                    <Button className='btn-common' onClick={() => {onSaveDevice('INSERT', obj, inx)}}><FontAwesomeIcon icon="floppy-disk" /></Button>
                                    <Button className='btn-common' onClick={() => {onSaveDevice('DELETE', obj, inx)}}><FontAwesomeIcon icon="xmark" /></Button> 
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default ConfigDevice;