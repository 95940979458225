import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Modal } from 'antd';
import { withdraw } from '../../model/withdraw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonDataTable from '../common/common-data-table';
import { api_product, api_withdraw } from '../../api/product';
import CommonSearch from '../common/common-search';
import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { isEmpty } from '../../utils/util';
import CommonDetail from '../common/common-detail';
import WithdrawProduct from './withdraw-product';

interface props {
    withdraw: withdraw,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const WithdrawDetail: React.FC<props> = (prop) => {

    const productCodeRef = useRef<InputRef>(null);

    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [data, setData] = useState({});
    const [product, setProduct] = useState<any>({});
    const [productData, setProductData] = useState([]);
    const [productList, setProductList] = useState([]);

    const [productCode, setProductCode] = useState('');
    const [productQty, setProductQty] = useState(1);
    const [remark, setRemark] = useState('');

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const productColumn: any = [
        { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code', width: '160px', edit: false },
        { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name', width: '230px', edit: false },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity', type: 'INPUT', width: '100px', edit: true },
        { title: 'น้ำหนัก', dataIndex: 'weight', key: 'weight', type: 'INPUT', width: '100px', edit: true },
    ]

    const init = async () => {
        setData(prop.withdraw);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);

        if (prop.withdraw.id > 0) {
            setIsUpdate(true);
            setProductData([]);
            let getWithdraw = await api_withdraw('GET', {}, prop.withdraw.id);
            setData(getWithdraw);
            setInputData(getWithdraw);
            prop.onChange(getWithdraw);
        }
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setRemark(object.remark != null ? object.remark : '');
        setProductData(object.detail != null ? object.detail : []);
        setTotalQuantity(object.quantity);
        setTotalWeight(object.weight);
        setTotalCost(object.cost);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onProductChange = (e: any) => {
        setProductCode(e.target.value);
    }

    const onProductQtyChange = (e: any) => {
        setProductQty(isEmpty(e.target.value) ? 0 : parseInt(e.target.value));
    }

    const onSearchInputChange = (e: any) => {
        if (e.keyCode == 13) {
            let code = e.target.value;
            if (e.target.name == 'product_qty') {
                code = productCode;
            }

            let getProduct = productList.filter(x => { return x['product_code'] == code });
            if (getProduct.length > 0) {
                onManageProduct('A', getProduct[0]);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });
            }
            
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        }

        setIsOpen(false);
    }

    const onManageProduct = (key: string, e: any) => {
        let object: any = data;

        if (key == 'A') {
            if (object.detail.filter((x: any) => { return x['product'] == e['id']}).length > 0) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้อยู่ในรายการแล้ว')
                });
            } else {
                object.detail = [
                    ...object.detail,
                    ...[{
                        "key": (object.detail.length + 1),
                        "id": 0,
                        "withdraw": 0,
                        "quantity": isEmpty(productQty) || productQty <= 0 ? 1 : productQty,
                        "weight": 0,
                        "cost": e['product_cost'],
                        "remark": '',
                        "status": "Y",
                        "product": e['id'],
                        "product_code": e['product_code'],
                        "product_name": e['product_name'],
                        "product_name_other": e['product_name_other'],
                        "product_type": e['product_type'],
                        "product_unit": e['product_unit'],
                    }]
                ];

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else if (key == 'E') {
            var index = object.detail.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object.detail[index] = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setProductQty(1);
        calculateTotal();
    }

    const onActionProduct = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let detail: any = [];

                object.detail.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        detail.push(x);
                    }
                });
                object.detail = detail;

                setData(object);
                setInputData(object);
                prop.onChange(object);
            } else if (mode == 'edit') {
                setProduct(object.detail.filter((x: any) => { return x['key'] == key})[0]);
                setOpen(true);
            }
        } else {
            object.detail = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        calculateTotal();
    }

    const onEditedProduct = () => {
        if (isEmpty(product['cost']) || isEmpty(product['quantity']) || product['quantity'] < 0 || isEmpty(product['weight']) || product['weight'] < 0) {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        } else {
            onManageProduct('E', product);
            setOpen(false);
        }
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }

    const calculateTotal = () => {
        let quantity: number = 0; 
        let weight: number = 0;
        let cost: number = 0;
        let object: any = data;

        if (object.detail.length > 0) {
            object.detail.forEach((object: any) => {
                quantity += parseInt(object['quantity']);
                weight += parseInt(object['weight']);
                cost += ((parseInt(object['quantity']) < 1 ? 1 : parseInt(object['quantity'])) * parseInt(object['cost']));
            });
        }

        object['quantity'] = quantity;
        object['weight'] = weight;
        object['cost'] = cost

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox' style={{'marginBottom': '50px', 'maxHeight': '50%'}}>
                    <div className='searchDataBox'>
                        <label>ค้นหาสินค้า</label>
                        <Input ref={productCodeRef} name="product_code" value={productCode} placeholder="รหัสสินค้า" onChange={onProductChange} onKeyUp={onSearchInputChange} />
                        <label>จำนวน</label>
                        <Input name="product_qty" value={productQty} placeholder="" style={{width: '50px'}} onChange={onProductQtyChange} onKeyUp={onSearchInputChange} />
                        <Button className='btn-common' onClick={() => {onOpenSelect('product')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                    </div>
                    <CommonDataTable columns={productColumn} data={[...productData]} operation='OUT_TABLE' scollX={768} onChange={onActionProduct} />
                </div>
                <div className='footerBox'>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวนรวม</label>
                                <div>
                                    <Input name="total_quantity" value={totalQuantity} placeholder="จำนวนรวม" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>น้ำหนักรวม</label>
                                <div>
                                    <Input name="total_weight" value={totalWeight} placeholder="น้ำหนักรวม" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ต้นทุนรวม</label>
                                <div>
                                    <Input name="total_cost" value={totalCost} placeholder="จำนวนรวม" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>หมายเหตุ{isUpdate ? <span> *</span> : null}</label>
                                <div>
                                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CommonDetail width='40%' mode={'ADD'} title={'รายละเอียดสินค้า'} open={open} onClosed={() => { setOpen(false) }} onSaved={onEditedProduct} 
                element={ <WithdrawProduct product={{...product}} onChange={setProduct} /> } 
            />

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default WithdrawDetail;