import axios from 'axios';
import { base_url } from './api-config'; 

const getHeaders = (operation: string, key_id?: number) => {
    let header: any = {
        "Content-Type": "application/json",
        "operation": operation
    }
    if (key_id && key_id != undefined && key_id != null) {
        header["key-id"] = key_id
    }

    return { 
        headers: header
    }
}

export const api_employee = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/other/employee`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        });

    return result;
}
