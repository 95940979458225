import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, InputRef, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonDataTable from '../common/common-data-table';
import { api_check_stock, api_product, api_stock } from '../../api/product';
import CommonSearch from '../common/common-search';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { checkStock } from '../../model/check-stock';
import { isEmpty } from '../../utils/util';

interface props {
    checkStock: checkStock,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const CheckStockDetail: React.FC<props> = (prop) => {

    const productCodeRef = useRef<InputRef>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [data, setData] = useState({});
    const [productData, setProductData] = useState([]);
    const [productList, setProductList] = useState([]);

    const [productCode, setProductCode] = useState('');
    const [productQty, setProductQty] = useState(1);
    const [remark, setRemark] = useState('');
    const [isUpdateStock, setIsUpdateStock] = useState(false);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalLost, setTotalLost] = useState(0);
    const [totalOver, setTotalOver] = useState(0);

    const productColumn: any = [
        { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code', width: '160px', fixed: 'left', edit: false },
        { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name', fixed: 'left', edit: false },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity', width: '100px', edit: false },
        { title: 'นับได้จริง', dataIndex: 'countable', key: 'countable', type: 'INPUT', width: '100px', edit: true },
    ]

    const init = async () => {
        setData(prop.checkStock);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);

        if (prop.checkStock.id > 0) {
            setProductData([]);
            let getCheckStock = await api_check_stock('GET', {}, prop.checkStock.id);
            setData(getCheckStock);
            setInputData(getCheckStock);
        }
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setRemark(object.remark != null ? object.remark : '');
        setIsUpdateStock(object.is_update_stock);
        setProductData(object.detail != null ? object.detail : []);
        setTotalQuantity(object.quantity);
        setTotalLost(object.lost);
        setTotalOver(object.over);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onProductChange = (e: any) => {
        setProductCode(e.target.value);
    }

    const onProductQtyChange = (e: any) => {
        setProductQty(isEmpty(e.target.value) ? 0 : parseInt(e.target.value));
    }

    const onSearchInputChange = (e: any) => {
        if (e.keyCode == 13) {
            let code = e.target.value;
            if (e.target.name == 'product_qty') {
                code = productCode;
            }

            let getProduct = productList.filter(x => { return x['product_code'] == code });
            if (getProduct.length > 0) {
                onManageProduct('A', getProduct[0]);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });
            }
            
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        } else if (e.table == 'vendor') {
            onDataChange( {
                target: {
                    name: 'vendor',
                    value: e.id
                }
            })

            onDataChange( {
                target: {
                    name: 'vendor_name',
                    value: e.name
                }
            })
        }

        setIsOpen(false);
    }

    const onManageProduct = async (key: string, e: any) => {
        let object: any = data;

        if (key == 'A') {
            if (object.detail.filter((x: any) => { return x['product'] == e['id']}).length > 0) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้อยู่ในรายการแล้ว')
                });
            } else {
                let getStock = await api_stock('GET', {}, e['id']);

                object.detail = [
                    ...object.detail,
                    ...[{
                        "key": (object.detail.length + 1),
                        "id": 0,
                        "product": e['id'],
                        "quantity": getStock.length > 0 ? getStock[0]['quantity'] : 0,
                        "countable": isEmpty(productQty) || productQty < 0 ? 0 : productQty,
                        "status": "Y",
                        "product_code": e['product_code'],
                        "product_name": e['product_name'],
                    }]
                ];

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else if (key == 'E') {
            var index = object.detail.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object.detail[index] = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setProductQty(1);
        calculateTotal();
    }

    const onActionProduct = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let detail: any = [];

                object.detail.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        detail.push(x);
                    }
                });
                object.detail = detail;

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else {
            object.detail = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        calculateTotal();
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }

    const calculateTotal = () => {
        let quantity: number = 0; 
        let lost: number = 0;
        let over: number = 0;
        let object: any = data;

        if (object.detail.length > 0) {
            object.detail.forEach((object: any) => {
                let dif = (parseInt(object['quantity']) - parseInt(object['countable']))
                quantity += parseInt(object['quantity']);
                lost += dif > 0 ? dif : 0;
                over += dif < 0 ? (dif * -1) : 0;
            });
        }

        object['quantity'] = quantity;
        object['lost'] = lost;
        object['over'] = over

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox' style={{'marginBottom': '50px', 'maxHeight': '50%'}}>
                    <div className='searchDataBox'>
                        <label>ค้นหาสินค้า</label>
                        <Input ref={productCodeRef} name="product_code" value={productCode} placeholder="รหัสสินค้า" onChange={onProductChange} onKeyUp={onSearchInputChange} />
                        <label>จำนวน</label>
                        <Input name="product_qty" value={productQty} placeholder="" style={{width: '50px'}} onChange={onProductQtyChange} onKeyUp={onSearchInputChange} />
                        <Button className='btn-common' onClick={() => {onOpenSelect('product')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                    </div>
                    <CommonDataTable columns={productColumn} data={[...productData]} operation='OUT_TABLE' scollX={768} onChange={onActionProduct} />
                </div>
                <div className='footerBox'>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>สินค้าปกติ</label>
                                <div>
                                    <Input name="total_quantity" value={totalQuantity} placeholder="จำนวนรวม" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>สินค้าหาย</label>
                                <div>
                                    <Input name="total_weight" value={totalLost} placeholder="สินค้าหาย" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>สินค้าเกิน</label>
                                <div>
                                    <Input name="total_cost" value={totalOver} placeholder="สินค้าเกิน" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>หมายเหตุ</label>
                                <div>
                                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label></label>
                                <div className='formInputCheckbox'>
                                    <Checkbox name='is_update_stock' checked={isUpdateStock} onChange={onDataChange}>อัพเดทสต๊อก</Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default CheckStockDetail;