import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

type loadingState = {
    active: boolean;
}

const initialValues:loadingState = {
    active: false
}

const loadingSlice = createSlice({
    name: "loading",
    initialState: initialValues,
    reducers: {
        activeLoading: (state: loadingState, action: PayloadAction<loadingState>) => {
            state.active = action.payload.active;
        }
    },
    extraReducers: (builder) => {

    }
});

export const { activeLoading } = loadingSlice.actions;
export const loadingSelector = (store: RootState) => store.loadingReducer;
export default loadingSlice.reducer;