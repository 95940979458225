import React, { useState, useEffect } from 'react';
import { Button, Input, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { filterOption, filterOptionSort } from '../../utils/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from '../../utils/util';

interface props {
    columns: any, 
    data: any, 
    operation: string,
    scollX: number,
    onChange: React.Dispatch<React.SetStateAction<Array<object>>>
}

const { Option } = Select;

const CommonDataTable: React.FC<props> = (prop) => {

    const [dataTable, setDataTable] = useState<any>([]);

    const init = () => {    
        onSetData(prop.data);
    }

    const onSetData = (data: any) => {
        if (data.length > 0) {
            setDataTable(JSON.parse(JSON.stringify(data)));
        } else {
            setDataTable([]);
        }
    }

    const onSetColumn = () => {
        if (prop.columns.length > 0) {
            const col: ColumnsType<any> = [];

            prop.columns.forEach((object: any) => {
                let config: any = {};
                config['key'] = object.key;
                config['title'] = object.title;
                config['dataIndex'] = object.dataIndex;
                // config['editable'] = true;

                if ('width' in object) {
                    config['width'] = object.width;
                }

                if ('fixed' in object) {
                    config['fixed'] = object.fixed;
                }

                if (object.type === 'INPUT') {
                    config['render'] = (_: any, record: any) => {
                        return dataTable.length >= 1 ? ( 
                            <>
                                { record.status === 'N' ?
                                    <label>{dataTable[record.key - 1][object.key]}</label>
                                    : <Input value={dataTable[record.key - 1][object.key]} name={object.key} 
                                        style={object.key == "countable" && record.quantity > record.countable ? 
                                            {"border": "1px solid #eb1818"} :
                                            object.key == "countable" && record.quantity < record.countable ? 
                                            {"border": "1px solid #0079c5"} : 
                                            {}} 
                                        onChange={(e) => { onDataChange(e, (record.key - 1)) }} 
                                      />
                                }
                            </>
                        ) : null
                    }
                } 
                else if (object.type === 'SELECT') {
                    config['width'] = '200px';
                    config['render'] = (_: any, record: any) => {
                        return dataTable.length >= 1 ? (
                            <>
                                { record.status === 'N' ?
                                    <label>{ getTextFormSelect(object.list, dataTable[record.key - 1][object.key]) }</label>
                                    : <Select value={dataTable[record.key - 1][object.key]} showSearch filterOption={filterOption} onChange={(e: any) => { onMappingDataBeforeChange(e, object.key, (record.key - 1)) }} >
                                        { isEmpty(object.listDefault) && <Option key={0} value={0} code={""} disabled>กรุณาเลือก{object.title}</Option> }
                                        {
                                            object.list.map((obj: any) => {
                                                return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                            })
                                        }
                                    </Select>
                                }
                            </>
                        ) : null
                    }
                } else if (object.type === 'LINK') {
                    config['render'] = (_: any, record: any) => {
                        return dataTable.length >= 1 ? (
                            <>
                                { record.id < 0 ?
                                    <label style={{cursor: 'pointer', color: 'red'}} onClick={() => {prop.onChange([{mode: 'link', data: record}]);}}>{_}</label>
                                    : <label>{_}</label>
                                }
                            </>
                        ) : null
                    }
                } else if (object.type === 'CAL') {
                    config['render'] = (_: any, record: any, index: any) => { 
                        let first = isEmpty(record[object.sumField[0]]) ? 0 : record[object.sumField[0]];
                        let second = isEmpty(record[object.sumField[1]]) ? 0 : record[object.sumField[1]];
                        if (object.operationCal === 'div') {
                            return <label>{parseFloat((parseFloat(first + '') / parseFloat(second + '') + '')).toFixed(2)}</label>
                        } else {
                            return <label>{first * second}</label>
                        }
                    }
                } else if (object.key === 'rec') {
                    config['render'] = (_: any, record: any, index: any) => { 
                        return <label>{index + 1}</label>
                    }
                }

                col.push(config);
            });

            if (prop.operation == 'IN_TABLE') {
                col.push({ key: 'operation', title: '', dataIndex: 'operation', width: '130px', fixed: 'right', render: (_, record) =>
                    dataTable.length >= 1 ? (
                        <> 
                            { record.status === 'N' 
                                ? <div className='formInputDataTable'>
                                    <Button className='btn-common' onClick={() => { onEditing(record.key) }}><FontAwesomeIcon icon="pen-to-square" /></Button>
                                    <Button className='btn-common' onClick={() => { onRemove(record.key) }}><FontAwesomeIcon icon="trash-can" /></Button> 
                                </div>
                                : <div className='formInputDataTable'>
                                    <Button className='btn-common' onClick={() => { onSaveRow(record.key) }}><FontAwesomeIcon icon="floppy-disk" /></Button>
                                    <Button className='btn-common' onClick={() => { onCancel(record.key) }}><FontAwesomeIcon icon="xmark" /></Button> 
                                </div>
                            }
                        </>
                    ) : null
                });
            } else {
                col.push({ key: 'operation', title: '', dataIndex: 'operation', width: '80px', fixed: 'right', render: (_, record) =>
                    dataTable.length >= 1 ? (
                        <> 
                            <div className='formInputDataTable'>
                                {/* <Button className='btn-common' onClick={() => { onEditingOut(record.key) }}><FontAwesomeIcon icon="pen-to-square" /></Button> */}
                                <Button className='btn-common' onClick={() => { onRemoveOut(record.key) }}><FontAwesomeIcon icon="trash-can" /></Button> 
                            </div>
                        </>
                    ) : null
                });
            }

            return col;
        }
    }

    const getTextFormSelect = (list: any, value: number) => {
        let newList = [...list];
        let object: any = newList.filter((x: any) => { return x.id == value})[0];
        if (value == 0 || object == undefined || !('name' in object)) {
            return '-';
        }

        return object['name'];
    }

    const onAdd = () => {
        let fields: any = {key: (dataTable.length + 1), status: 'Y'};
        prop.columns.forEach((object: any) => {
            fields[object.dataIndex] = (object.type === 'INPUT' ? '' : 0);
        });

        setDataTable([...dataTable, fields]);
    }

    const onEditing = (key: React.Key) => {
        const list = [...dataTable];
        const index = list.findIndex((item) => key === item.key);

        list[index]['status'] = 'Y';
        setDataTable(list);
    }

    const onSaveRow = (key: React.Key) => {
        const list = [...dataTable];
        const index = list.findIndex((item) => key === item.key);

        list[index]['status'] = 'N';
        setDataTable(list);
        prop.onChange(list);
    }

    const onCancel= (key: React.Key) => {
        const list = [...dataTable];
        const index = list.findIndex((item) => key === item.key);

        list[index]['status'] = 'N';
        setDataTable(list);
    }

    const onRemove = (key: React.Key) => {
        let list: any = [];
        let count = 0;
        dataTable.forEach((object: any) => {
            if (object.key != key) {
                count++;
                object['key'] = count;
                list.push(object);
            }
        });

        setDataTable(list);
        prop.onChange(list);
    }

    const onEditingOut = (key: React.Key) => {
        prop.onChange([{key: key, mode: 'edit'}]);
    }

    const onRemoveOut = (key: React.Key) => {
        prop.onChange([{key: key, mode: 'remove'}]);
    }

    const onDataChange = (e: any, index: number) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? Boolean(e.target.checked) 
                : e.target.type === 'radio' ? Boolean(e.target.value) 
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let list: any = [...dataTable];
        list[index][key] = value;
        setDataTable(list);

        prop.onChange(list);
    }

    const onMappingDataBeforeChange = (e: any, name: string, index: number) => {
        let object = {
            target: {
                name: name,
                value: e
            }
        }
        onDataChange(object, index);
    }

    useEffect(() => {
        init();
    }, [prop.data]);

    return (
        <>
            { prop.operation == 'IN_TABLE' ? 
                <Button className='btn-common' type="primary" onClick={onAdd}>
                    <FontAwesomeIcon icon="plus" /> เพิ่ม
                </Button> : null 
            }
            <Table 
                style={{ marginTop: '20px' }}
                rowKey="key"
                columns={onSetColumn()} 
                dataSource={[...dataTable]}
                pagination={false}
                scroll={{ x: prop.scollX, y: window.innerHeight - 500 }} 
            />
        </>
    );
}

export default CommonDataTable;