import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const daysTH = () => {
    return ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
}

export const monthsTH = () => {
    return ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
}

export const isEmpty = (data) => {
    return (data === undefined || data === null || data === "");
}

export const convertFormatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
}

export const convertFormatDateTime = (date, isISO) => {
    return convertFormatDate(date) 
        + (isISO ? 'T' : ' ')
        + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) 
        + ':' + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) 
        + ':' + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
}

export const exportExcel = (name, data) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType : 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(file, name + '-' + convertFormatDateTime(new Date()).replaceAll(' ', '-').replaceAll(':', '-') + fileExtension);
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const validateEmail = (value) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!value.match(validRegex)) {
        return false;
    } 

    return true;
}

export const getToken = () => {
    // chrome://flags/#enable-webrtc-hide-local-ips-with-mdns
    return new Promise(function(resolve, reject) {
        var RTCPeerConnection = /*window.RTCPeerConnection ||*/ window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
    
        if (!RTCPeerConnection) {
            reject('Your browser does not support this API');
        }
        
        var rtc = new RTCPeerConnection({iceServers:[]});
        var addrs = {};
        addrs["0.0.0.0"] = false;
        
        function grepSDP(sdp) {
            var hosts = [];
            var finalIP = '';
            sdp.split('\r\n').forEach(function (line) { 
                if (~line.indexOf("a=candidate")) {
                    var parts = line.split(' '),
                        addr = parts[4],
                        type = parts[7];
                    if (type === 'host') {
                        finalIP = addr;
                    }
                } else if (~line.indexOf("c=")) {
                    var parts = line.split(' '),
                        addr = parts[2];
                    finalIP = addr;
                }
            });
            return finalIP;
        }
        
        if (1 || window.mozRTCPeerConnection) {
            rtc.createDataChannel('', {reliable:false});
        };
        
        rtc.onicecandidate = function (evt) {
            if (evt.candidate) {
                var addr = grepSDP("a="+evt.candidate.candidate);
                resolve(addr);
            }
        };

        rtc.createOffer(function (offerDesc) {
            rtc.setLocalDescription(offerDesc);
        }, function (e) { console.warn("offer failed", e); });
    });
}