export interface withdraw {
    key: number,
    id: number,
    document_no: string,
    quantity: number,
    weight: number,
    cost: number,
    remark: string,
    status: number,
    create_date: string,
    update_date: string,
    create_by_name: string,
    detail: withdrawDetail[]
}

export interface withdrawDetail {
    key: number,
    id: number,
    withdraw: number,
    quantity: number,
    weight: number,
    cost: number,
    remark: string,
    status: number,
    product: number,
    product_code: string,
    product_name: string,
    product_name_other: string,
    product_type: number,
    product_unit: number,
}

export const initWithdraw = {
    key: 0,
    id: 0,
    document_no: "",
    quantity: 0,
    weight: 0,
    cost: 0,
    remark: "",
    status: 0,
    create_date: "",
    update_date: "",
    create_by_name: "",
    detail: []
}

export const initWithdrawDetail = {
    key: 0,
    id: 0,
    withdraw: 0,
    quantity: 0,
    weight: 0,
    cost: 0,
    remark: "",
    status: 0,
    product: 0,
    product_code: "",
    product_name: "",
    product_name_other: "",
    product_type: 0,
    product_unit: 0,
}