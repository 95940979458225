import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { member, initMember } from '../../model/member';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_member } from '../../api/member';
import CommonTableList from '../common/common-table-list';
import CommonDetail from '../common/common-detail';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import MemberDetail from './member-detail';
import { isEmpty, validateEmail } from '../../utils/util';

const Member: React.FC = (prop) => {

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [member, setMember] = useState<member>(initMember);

    const filterField: any = ['code', 'firstname', 'tel'];

    const placeholder_txt: string = 'รหัสสมาชิก / ชื่อ / เบอร์โทรศัพท์' 

    const columns: ColumnsType<member> = [
        { title: 'รหัสสมาชิก', dataIndex: 'code', key: 'code', fixed: 'left' },
        { title: 'ชื่อ', dataIndex: 'firstname', key: 'firstname' },
        { title: 'นามสกุล', dataIndex: 'lastname', key: 'lastname' },
        { title: 'เบอร์โทรศัพท์', dataIndex: 'tel', key: 'tel' },
        { title: 'อีเมล', dataIndex: 'email', key: 'email' },
        { title: 'กลุ่มสมาชิก', dataIndex: 'member_group_name', key: 'member_group_name', render: (_, record) =>
            data.length >= 1 ? (
                record.member_group > 0 ? <label>{record.member_group_name}</label> : <label>-</label>
            ) : null
        },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getMember = await api_member('LIST', {});
        setData(getMember);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onShowDetail = (mode: string, memberData?: member) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('เพิ่มสมาชิก')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขสมาชิก')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดสมาชิก')
        }

        setMode(mode);
        setMember(memberData !== undefined && memberData !== null ? memberData : initMember);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(member));
        if (member.id > 0) {
            operation = 'UPDATE';
            key_id = member.id;
        }

        if (!isEmpty(body['email']) && !validateEmail(body['email'])) {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกอีเมลให้ถูกต้อง')
            });
        } else if (!isEmpty(body['firstname']) && !isEmpty(body['tel'])) {
            body['firstname'] = isEmpty(body['firstname']) ? null : body['firstname']
            body['lastname'] = isEmpty(body['lastname']) ? null : body['lastname']
            body['tel'] = isEmpty(body['tel']) ? null : body['tel']
            body['email'] = isEmpty(body['email']) ? null : body['email']
            body['tax_id'] = isEmpty(body['tax_id']) ? null : body['tax_id']
            body['address'] = isEmpty(body['address']) ? null : body['address']
            body['remark'] = isEmpty(body['remark']) ? null : body['remark']
            body['member_group'] = isEmpty(body['member_group']) || body['member_group'] < 1 ? null : body['member_group']

            let res = await api_member(operation, body, key_id);
            if (res === 1) {
                setOpen(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { init(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }
    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_member('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setMember(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='member' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='member' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='40%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <MemberDetail member={{...member}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default Member;