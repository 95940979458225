import React, { useEffect, useState } from 'react';
import CommonDetail from '../common/common-detail';
import CommonTableList from '../common/common-table-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { api_good_receive } from '../../api/product';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { goodReceive, initGoodReceive } from '../../model/good-receive';
import GoodReceiveDetail from './good-receive-detail';
import { Modal } from 'antd';
import { isEmpty } from '../../utils/util';

const GoodReceive: React.FC = (prop) => {
    
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [goodReceive, setGoodReceive] = useState<goodReceive>(initGoodReceive);

    const filterField: any = ['document_no', 'vendor_name'];

    const placeholder_txt: string = 'เลขที่เอกสาร / ผู้ขายส่งสินค้า' 

    const columns: ColumnsType<any> = [
        { title: 'เลขที่เอกสาร', dataIndex: 'document_no', key: 'document_no', width: '200px', fixed: 'left' },
        { title: 'วันที่รับเข้า', dataIndex: 'create_date', key: 'create_date', width: '200px', },
        { title: 'วันที่แก้ไข', dataIndex: 'update_date', key: 'update_date', width: '200px', },
        { title: 'ผู้รับเข้า', dataIndex: 'create_by_name', key: 'create_by_name' },
        { title: 'ผู้ขายส่งสินค้า', dataIndex: 'vendor_name', key: 'vendor_name' },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity' },
        { title: 'น้ำหนัก', dataIndex: 'weight', key: 'weight' },
        { title: 'ต้นทุน', dataIndex: 'cost', key: 'cost' },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getGoodReceive = await api_good_receive('LIST', {});
        setData(getGoodReceive);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
        }
    }

    const onShowDetail = (mode: string, goodReceiveData?: goodReceive) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('เพิ่มการรับเข้า')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขการรับเข้า')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดการรับเข้า')
        }

        setMode(mode);
        setGoodReceive(goodReceiveData !== undefined && goodReceiveData !== null ? goodReceiveData : initGoodReceive);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(goodReceive));
        let validate = true;
        let validate_text = "ข้อมูลไม่ถูกต้อง";
        if (goodReceive.id > 0) {
            operation = 'UPDATE';
            key_id = goodReceive.id;

            if (body['remark'] == "") {
                validate = false;
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'กรุณากรอกหมายเหตุในการแก้ไข')
                });
                return false;
            }
        }

        if (validate && body['detail'] && body['detail'].length > 0) {
            let cost = 0;
            let price = 0;
            body.detail.forEach((obj: any) => {
                cost = isEmpty(obj['cost']) ? 0 : parseFloat(obj['cost']);
                price = isEmpty(obj['price']) ? 0 : parseFloat(obj['price']);
                obj['cost'] = cost;
                obj['price'] = price;
                if (cost <= 0) {
                    validate_text = "ต้นทุนไม่ถูกต้อง";
                    validate = false;
                    return false;
                }
                if (price < cost) {
                    validate_text = "ราคาขายน้อยกว่าต้นทุน";
                    validate = false;
                    return false;
                }
            });
        }

        if (validate) {
            body['quantity'] = isEmpty(body['quantity']) ? 0 : body['quantity']
            body['weight'] = isEmpty(body['weight']) ? 0 : body['weight']
            body['cost'] = isEmpty(body['cost']) ? 0 : parseFloat(body['cost'])
            body['vendor'] = body['vendor'] < 1 ? null : body['vendor']
            body['purchase_order'] = body['purchase_order'] < 1 ? null : body['purchase_order']
            body['remark'] = isEmpty(body['remark']) ? null : body['remark']

            let res = await api_good_receive(operation, body, key_id);
            if (res === 1) {
                setOpen(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { init(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', validate_text)
            });
        }

    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_good_receive('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setGoodReceive(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='good-receive' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='good-receive' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='100%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <GoodReceiveDetail goodReceive={{...goodReceive}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default GoodReceive;