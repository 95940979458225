import { DateObject } from "react-multi-date-picker";
import { getDateFromDatePicker } from "../utils/datepicker";

export interface productType {
    key: number,
    id: number,
    name: string,
}

export interface unitType {
    key: number,
    id: number,
    name: string,
}

export interface vat {
    key: number,
    id: number,
    name: string,
}

export interface vendor {
    key: number,
    id: number,
    code: string,
    name: string,
    tax_id?: string,
    tel?: string,
    fax?: string,
    email?: string,
    address?: string,
    remark?: string,
}

export const initVendor = {
    key: 0,
    id: 0,
    code: "",
    name: "",
    tax_id: "",
    tel: "",
    fax: "",
    email: "",
    address: "",
    remark: "",
}

export interface product {
    key: number,
    id: number,
    product_code: string,
    product_name: string,
    product_name_other: string,
    product_type?: number,
    product_type_name: string,
    product_unit?: number,
    product_unit_name: string,
    quantity?: number,
    weight?: number,
    product_normal_type: number,
    product_cost: string,
    price?: number,
    price_retail: number,
    price_wholesale: number,
    price_retail_member: number,
    price_wholesale_member: number,
    init_order_amount: string,
    point_purchase: string,
    overflow_point: string,
    limit_quantity: string,
    vat?: number,
    point: string,
    is_cut_stock: number,
    is_tier_price: number,
    is_avg_cost: number,
    expire_date: string,
    vendor?: any,
    vendor_name: string,
    remark: string,
    status: number,
    tier_price: string,
}

export const initProduct = {
    key: 0,
    id: 0,
    product_code: "",
    product_name: "",
    product_name_other: "",
    product_type: 0,
    product_type_name: "",
    product_unit: 0,
    product_unit_name: "",
    quantity: 1,
    weight: 0,
    product_normal_type: 1,
    product_cost: "",
    price: 0,
    price_retail: 0,
    price_wholesale: 0,
    price_retail_member: 0,
    price_wholesale_member: 0,
    init_order_amount: "",
    point_purchase: "",
    overflow_point: "",
    limit_quantity: "",
    vat: -1,
    point: "",
    is_cut_stock: 0,
    is_tier_price: 0,
    is_avg_cost: 0,
    expire_date: getDateFromDatePicker(new DateObject()),
    vendor: [],
    vendor_name: "",
    remark: "",
    status: 1,
    tier_price: '[]'
}

export interface employee {
    key: number,
    id: number,
    code: string,
    name: string,
    tel?: string,
    address?: string,
    privilege?: number,
    status: number,
    create_date: string
}

export const initEmployee = {
    key: 0,
    id: 0,
    code: "",
    name: "",
    tel: "",
    address: "",
    privilege: 2,
    status: 0,
    create_date: ""
}

export interface saleType {
    key: number,
    id: number,
    name: string,
}

export const initSaleType = {
    key: 0,
    id: 0,
    name: "",
}

export interface paymentType {
    key: number,
    id: number,
    code: string,
    name: string,
}

export const initPaymentType = {
    key: 0,
    id: 0,
    code: "",
    name: "",
}