import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Checkbox, Button, Modal  } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { InputRef } from 'antd/es/input';
import logo from "../assets/images/logo.png";
import { increase } from "../store/user/slice";
import { useAppDispatch } from "../store/index";
import { encode as base64_encode } from 'js-base64';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api_employee } from '../api/employee';
import { getToken } from '../utils/util';

const Login: React.FC = (prop) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(true);
    const passRef = useRef<InputRef>(null);
    const btnRef = useRef<HTMLInputElement>(null);

    const onChange = (e: CheckboxChangeEvent) => {
        setRemember(e.target.checked)
    };

    const onUsernameChange = (event: any) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event: any) => {
        setPassword(event.target.value);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onLogin = async () => {
        let device = await getToken();
        let body = {
            'code': username,
            'password': password,
            'device': device
        }

        let data = await api_employee("LOGIN", body);
        if (data && Object.keys(data).length > 0) {
            if (data["id"] > 0 && data['login_status'] == 0) {
                if (remember) {
                    window.localStorage.setItem("uuid", base64_encode(JSON.stringify(data)));
                }
                window.localStorage.setItem("p", base64_encode(JSON.stringify(data.privilege)));
                dispatch(increase(data))
                navigate(`/${data["default_page"]}`)
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', 'ไม่สามารถเข้าสู่ระบบได้'),
                });
            }
        } else {
            Modal.error({
                ...getConfigModal('ผิดพลาด', 'รหัสผู้ใช้ หรือรหัสผ่านไม่ถูกต้อง'),
            });
        }
    }

    const nextFocus = (event: any, key: number) => {
        if (event.keyCode === 13) {
            if (key === 0) {
                passRef.current?.focus();
            } else if (key === 1) {
                btnRef.current?.click();
            }
        }
    }

    useEffect(() => {
        getToken().then((item: any) => {
            console.warn(item);
        });
    }, []);

    return (
        <>
            <div className='loginContainer'>
                <div className='logoBox'>
                    <div className='loginInput'>
                        <FontAwesomeIcon icon="user" className='userLoginIcon' />
                        <div className='logoBoxInput'>
                            <label>รหัสผู้ใช้</label>
                            <Input className='uppercase' value={username} placeholder={'กรุณากรอกรหัสผู้ใช้'} onChange={onUsernameChange} onKeyUp={(e) => { nextFocus(e, 0) }} style={{textTransform: 'uppercase', marginTop: '10px'}} />
                        </div>
                        <div className='logoBoxInput'>
                            <label>รหัสผ่าน</label>
                            <Input.Password ref={passRef} value={password} placeholder={'กรุณากรอกรหัสผ่าน'} onChange={onPasswordChange} onKeyUp={(e) => { nextFocus(e, 1) }} style={{marginTop: '10px'}} />
                        </div>
                        {/* <Checkbox onChange={onChange} style={{marginTop: "10px"}}>จดจำฉัน</Checkbox> */}
                        <div style={{marginTop: "20px", textAlign: "center"}}>
                            <Button className='btn-common' ref={btnRef} type="primary" onClick={onLogin}>
                                เข้าสู่ระบบ
                            </Button>
                        </div>
                    </div>
                    <div className='loginLogo'>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;