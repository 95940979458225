import React, { useEffect, useState } from 'react';
import { employee } from '../../model/master';
import { Input, Radio, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';

interface props {
    employee: employee,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const { Option } = Select;

const EmployeeDetail: React.FC<props> = (prop) => {

    const [data, setData] = useState<any>({});

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [privilege, setPrivilege] = useState(2);

    const [privilegeList, setPrivilegeList] = useState<any>([]);

    const init = async () => {
        setPrivilegeList([
            { key: 1, id: 1, name: 'แอดมิน' },
            { key: 2, id: 2, name: 'พนักงาน' }
        ])
    }

    const setInputData = (object: any) => {
        setCode(object.code);
        setName(object.name);
        setTel(object.tel);
        setAddress(object.address);
        setPassword(object.password);
        setPrivilege(object.privilege)
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;
        if (key === 'password') {
            object['isPasswordChange'] = true;
        }

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }
    
    useEffect(() => {
        init();
        setData(prop.employee);
        setInputData(prop.employee);
    }, []);


    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox'>
                    <div className='InputBox'>
                        <div className='formInput'>
                            <label>รหัสพนักงาน</label>
                            <Input name="code" value={code} placeholder="E000X" onChange={onDataChange} disabled />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อพนักงาน<span> *</span></label>
                            <Input name="name" value={name} placeholder={'กรุณาป้อนชื่อพนักงาน'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>เบอร์โทรศัพท์<span> *</span></label>
                            <Input name="tel" value={tel} placeholder={'กรุณาป้อนเบอร์โทรศัพท์'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ที่อยู่</label>
                            {/* <Input name="address" value={address} placeholder={'กรุณาป้อนที่อยู่'} onChange={onDataChange} /> */}
                            <TextArea
                                name="address"
                                value={address}
                                onChange={onDataChange}
                                placeholder="กรุณาป้อนที่อยู่"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </div>
                        <div className='formInput'>
                            <label>รหัสผ่าน<span> *</span></label>
                            <Input.Password name="password" value={password} placeholder={'กรุณาป้อนรหัสผ่าน'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label></label>
                            <div className='formInputRadio'>
                                <Radio.Group name="privilege" value={privilege} onChange={onDataChange}>
                                    <Radio value={1}>แอดมิน</Radio>
                                    <Radio value={2}>พนักงาน</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeDetail;