export interface sale {
    key: number,
    id: number,
    document_no: string,
    member: number,
    sale_type: number,
    quantity: number,
    weight: number,
    price: number,
    total_price: number,
    discount: number,
    discount_bill: number,
    receive: number,
    change: number,
    vat: number,
    vat_amount: number,
    point: number,
    payment_type: number,
    remark: string,
    status: number,
    create_date: string,
    update_date: string,
    create_by_name: string,
    detail: saleDetail[]
}

export interface saleDetail {
    key: number,
    id: number,
    invoice: number,
    quantity: number,
    weight: number,
    price: number,
    total_price: number,
    discount: number,
    point: number,
    status: number,
    product: number,
    product_code: string,
    product_name: string,
    product_name_other: string,
    product_type: number,
    product_unit: number,
    price_retail: number,
    price_wholesale: number,
}

export const initSale = {
    key: 0,
    id: 0,
    document_no: "",
    member: 0,
    sale_type: 1,
    quantity: 0, 
    weight: 0,
    price: 0,
    total_price: 0,
    discount: 0,
    discount_bill: 0,
    receive: 0,
    change: 0,
    vat: -1,
    vat_amount: 0,
    point: 0,
    payment_type: 1,
    remark: "",
    status: 0,
    create_date: "",
    update_date: "",
    create_by_name: "",
    detail: []
}

export const initSaleDetail = {
    key: 0,
    id: 0,
    invoice: 0,
    quantity: 0,
    weight: 0,
    price: 0,
    total_price: 0,
    discount: 0,
    point: 0,
    status: 0,
    product: 0,
    product_code: "",
    product_name: "",
    product_name_other: "",
    product_type: 0,
    product_unit: 0,
    price_retail: 0,
    price_wholesale: 0,
}

export interface workShift {
    key: number,
    id: number,
    device: string,
    e1000: number,
    e500: number,
    e100: number,
    e50: number,
    e20: number,
    e10: number,
    e5: number,
    e2: number,
    e1: number,
    e050: number,
    e025: number,
    c1000: number,
    c500: number,
    c100: number,
    c50: number,
    c20: number,
    c10: number,
    c5: number,
    c2: number,
    c1: number,
    c050: number,
    c025: number,
    expected: number,
    total: number,
    create_by: number,
}

export const initWorkShift = {
    key: 0,
    id: 0,
    device: '',
    e1000: 0,
    e500: 0,
    e100: 0,
    e50: 0,
    e20: 0,
    e10: 0,
    e5: 0,
    e2: 0,
    e1: 0,
    e050: 0,
    e025: 0,
    c1000: 0,
    c500: 0,
    c100: 0,
    c50: 0,
    c20: 0,
    c10: 0,
    c5: 0,
    c2: 0,
    c1: 0,
    c050: 0,
    c025: 0,
    expected: 0,
    total: 0,
    create_by: 0
}
