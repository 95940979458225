import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { memberGroup, initMemberGroup } from '../../model/member';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_member_group } from '../../api/member';
import CommonTableList from '../common/common-table-list';
import CommonDetail from '../common/common-detail';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { isEmpty, validateEmail } from '../../utils/util';
import MemberGroupDetail from './member-group-detail';


const MemberGroup: React.FC = (prop) => {

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [memberGroup, setMemberGroup] = useState<memberGroup>(initMemberGroup);

    const filterField: any = ['name'];

    const placeholder_txt: string = 'ชื่อ' 

    const columns: ColumnsType<memberGroup> = [
        { title: 'ชื่อ', dataIndex: 'name', key: 'name' },
        { title: 'จำนวนสมาชิก', dataIndex: 'members_count', key: 'members_count' },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id, record.members_count) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getMemberGroup = await api_member_group('LIST', {});
        setData(getMemberGroup);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onShowDetail = (mode: string, memberGroupData?: memberGroup) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('เพิ่มกลุ่มสมาชิก')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขกลุ่มสมาชิก')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดกลุ่มสมาชิก')
        }

        setMode(mode);
        setMemberGroup(memberGroupData !== undefined && memberGroupData !== null ? memberGroupData : initMemberGroup);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(memberGroup));
        if (memberGroup.id > 0) {
            operation = 'UPDATE';
            key_id = memberGroup.id;
        }

        if (!isEmpty(body['name'])) {
            body['name'] = isEmpty(body['name']) ? null : body['name']
            body['members'] = body['members'].length <= 0 ? [] : body['members']

            let res = await api_member_group(operation, body, key_id);
            if (res === 1) {
                setOpen(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { init(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }
    }

    const onDelete = async (key_id: number, members_count: number | undefined) => {
        if (members_count != undefined && members_count > 0) {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'ไม่สามารถลบได้ เนื่องจากมีสมากชิกอยู่ในกลุ่ม')
            });
        } else {
            Modal.confirm({
                ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
                ...{
                    async onOk() { 
                        let res = await api_member_group('DELETE', {}, key_id);
                        if (res === 1) {
                            Modal.success({
                                ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                                ...{ onOk() { init(); }}
                            });
                        } else {
                            Modal.error({
                                ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                            });
                        }
                    }
                }
            });
        }
    }

    const onDataDetailChange = (payload: any) => {
        setMemberGroup(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='member' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='member-group' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='40%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <MemberGroupDetail memberGroup={{...memberGroup}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default MemberGroup;