import React, { useEffect, useRef, useState } from 'react';
import CommonSearch from '../common/common-search';
import CommonDataTable from '../common/common-data-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, InputRef, Modal, Radio } from 'antd';
import { api_member } from '../../api/member';
import { initMember, member } from '../../model/member';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { saleType, initSaleType } from '../../model/master';
import { sale, initSale } from '../../model/sale';
import { paymentType, initPaymentType } from '../../model/master';
import { api_product } from '../../api/product';
import { api_payment_type, api_sale, api_sale_type, api_work_shift } from '../../api/sale';
import { convertFormatDateTime, getToken, isEmpty } from '../../utils/util';
import WorkShift from './work-shift';
import ShortCut from './shortcut';

const Sale: React.FC = (prop) => {

    const productCodeRef = useRef<InputRef>(null);

    const [data, setData] = useState<sale>(initSale);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCharge, setIsOpenCharge] = useState(false);
    const [isOpenWorkShift, setIsOpenWorkShift] = useState(false);
    const [isOpenShortcut, setIsOpenShortcut] = useState(false);
    const [lock, setLock] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [pauseData, setPauseData] = useState([]);

    const [vat, setVat] = useState(-1);
    const [paymentType, setPaymentType] = useState(1);
    const [paymentTypeList, setPaymentTypeList] = useState<paymentType[]>([]);

    const [saleType, setSaleType] = useState(1);
    const [saleTypeList, setSaleTypeList] = useState<saleType[]>([]);

    const [memberCode, setMemberCode] = useState('');
    const [member, setMember] = useState<member>(initMember);
    const [memberList, setMemberList] = useState<member[]>([]);

    const [productCode, setProductCode] = useState('');
    const [productQty, setProductQty] = useState(1);
    const [productData, setProductData] = useState([]);
    const [productList, setProductList] = useState([]);

    const [price, setPrice] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [discountBill, setDiscountBill] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const [receive, setReceive] = useState(0);
    const [change, setChange] = useState(0);
    const [remark, setRemark] = useState('');

    const productColumn: any = [
        { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code', width: '160px', edit: false },
        { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name', width: '230px', edit: false },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity', type: 'INPUT', width: '100px', edit: true },
        { title: 'น้ำหนัก', dataIndex: 'weight', key: 'weight', type: 'INPUT', width: '100px', edit: true },
        { title: 'ราคา', dataIndex: 'price', key: 'price', width: '100px', edit: false },
        { title: 'ส่วนลด', dataIndex: 'discount', key: 'discount', type: 'INPUT', width: '100px', edit: true },
        { title: 'รวม', dataIndex: 'total_price', key: 'price', width: '100px', edit: false },
    ]

    const setDefaultValue = () => {
        setVat(-1);
        setPaymentType(1);
        setSaleType(1);
        setMemberCode('');
        setMember(initMember);

        setProductCode('');
        setProductData([]);

        setPrice(0);
        setVatAmount(0);
        setDiscount(0);
        setDiscountBill(0);
        setTotalPrice(0);

        setReceive(0);
        setChange(0);
        setRemark('');
        setData(initSale);
    }

    const checkWorkShift = async () => {
        let token = '';
        await getToken().then((item: string) => {
            token = item;
        });
        // let getWorkShift: any = await api_work_shift('GET_BY_CONDITION', {"device": token, "create_date": 1});
        // if (getWorkShift == null || getWorkShift == undefined || !('id' in getWorkShift)) {
        //     setLock(true);
        //     setIsOpenWorkShift(true);
        // } else {
        //     if (getWorkShift['status'] == 0) {
        //         Modal.warning({
        //             ...getConfigModal('แจ้งเตือน', 'กรุณาเปิดกะก่อนทำรายการ')
        //         });
        //     }
        // }
    }

    const init = async () => {
        let pause_data = isEmpty(localStorage.getItem('pause')) ? [] : JSON.parse(localStorage.getItem('pause') || "[]");
        setPauseData(pause_data);
        setDefaultValue();

        let getSaleType = await api_sale_type('LIST', {});
        setSaleTypeList(getSaleType);

        let getPaymentType = await api_payment_type('LIST', {});
        setPaymentTypeList(getPaymentType);

        let getMember = await api_member('LIST', {});
        setMemberList(getMember);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setProductData(object.detail != null ? object.detail : []);
        setDiscountBill(object.discount_bill);
        setVat(object.vat);
        calculate(object);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
    }

    const onChargeChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;
        
        let cal = object['receive'] - totalPrice;
        object['change'] = cal < 0 ? 0 : cal;

        setData(object);
        setPaymentType(object.payment_type);
        setReceive(object.payment_type == 2 ? totalPrice : object.receive);
        setChange(object.change);
        setRemark(object.remark);
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const onMemberChange = (e: any) => {
        setMemberCode(e.target.value);
    }

    const onSearchInputChange = (e: any, flag: string) => {
        if (e.keyCode == 13) {
            if (flag == 'M') {
                let getMember = memberList.filter(x => { return x['code'] == e.target.value });
                if (getMember.length > 0) {
                    onManageMember(getMember[0]);
                } else {
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'ไม่พบสมาชิกที่ค้นหา')
                    });
                }
            } else if (flag == 'P') {
                let code = e.target.value;
                if (e.target.name == 'product_qty') {
                    code = productCode;
                }

                let getProduct = productList.filter(x => { return x['product_code'] == code });
                if (getProduct.length > 0) {
                    onManageProduct('A', getProduct[0]);
                } else {
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                    });
                }
            }
            
            setMemberCode('');
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onManageMember = (e: any) => {
        setMember({
            key: 1,
            id: e['id'],
            code: e['code'],
            firstname: e['firstname'],
            lastname: e['lastname'],
            tel: e['tel'],
            email: e['email'],
            tax_id: e['tax_id'],
            address: e['address'],
            remark: e['remark'],
            member_group: e['member_group'],
            member_group_name: e['member_group_name'],
            status: e['status'],
            create_date: e['create_date']
        });
    }

    const onProductChange = (e: any) => {
        setProductCode(e.target.value);
    }

    const onProductQtyChange = (e: any) => {
        setProductQty(isEmpty(e.target.value) ? 0 : parseInt(e.target.value));
    }

    const onManageProduct = (key: string, e: any) => {
        let object: any = JSON.parse(JSON.stringify(data));

        if (key == 'A') {
            let index = object.detail.findIndex((x: any) => { return x['product'] == e['id']});
            if (index >= 0) {
                let qty = object.detail[index]['quantity'];
                object.detail[index]['quantity'] = parseInt(isEmpty(qty) ? 0 : qty) + (isEmpty(productQty) || productQty <= 0 ? 1 : productQty);
                // if (object.detail.filter((x: any) => { return x['product'] == e['id']}).length > 0) {
                //     Modal.warning({
                //         ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้อยู่ในรายการแล้ว')
                //     });
            } else {
                object.detail = [
                    ...object.detail,
                    ...[{
                        "key": (object.detail.length + 1),
                        "id": 0,
                        "withdraw": 0,
                        "quantity": isEmpty(productQty) || productQty <= 0 ? 1 : productQty,
                        "weight": 0,
                        "price": saleType == 1 ? e['price_retail'] : e['price_wholesale'],
                        "total_price": saleType == 1 ? e['price_retail'] : e['price_wholesale'],
                        "discount": 0,
                        "point": 0,
                        "status": "Y",
                        "product": e['id'],
                        "product_code": e['product_code'],
                        "product_name": e['product_name'],
                        "product_name_other": e['product_name_other'],
                        "product_type": e['product_type'],
                        "product_unit": e['product_unit'],
                        "price_retail": e['price_retail'],
                        "price_wholesale": e['price_wholesale'],
                    }]
                ];
            }

            setData(object);
            setInputData(object);
            updateProduct(object);
        } else if (key == 'E') {
            var index = object.detail.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object.detail[index] = e;

            setData(object);
            setInputData(object);
        }
        setProductQty(1);
    }

    const onActionProduct = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let detail: any = [];

                object.detail.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        detail.push(x);
                    }
                });
                object.detail = detail;

                setData(object);
                setInputData(object);
            }
        } else {
            object.detail = e;
            updateProduct(object);
        }
    }

    const updateProduct = (object: any) => {
        let detail: any = [];
        let price = 0;
        let qty = 0;
        let discount = 0;
        object.detail.forEach((x: any) => {
            price = object['sale_type'] == 1 ? x['price_retail'] : x['price_wholesale'];
            qty = isEmpty(x['quantity']) ? 0 : x['quantity'];
            discount = isEmpty(x['discount']) ? 0 : x['discount'];
            x['price'] = price;
            x['total_price'] = (price * qty) - discount;
            detail.push(x);
        });
        object.detail = detail;

        setData(object);
        setInputData(object);
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); setIsOpenWorkShift(false); setIsOpenShortcut(false);}}>ยกเลิก</Button>
        </>
    }

    const setFooterCharge = () => {
        return <>
            <br />
            <Button className='btn-common' onClick={() => { onValidateCharge() }}>คิดเงิน</Button>
            <Button className='btn-cancel' onClick={() => { setIsOpenCharge(false); }}>ยกเลิก</Button>
        </>
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'member') {
            onManageMember(e);
        } else if (e.table == 'product') {
            onManageProduct('A', e);
        } else if (e.table == 'pause') {
            let pause_data: any = JSON.parse(localStorage.getItem('pause') || "[]");
            pause_data = pause_data.filter((x: any) => { return Object.keys(x) != e['date'][0] });
            localStorage.setItem('pause', JSON.stringify(pause_data));

            let object = pauseData.filter((x: any) => { return Object.keys(x) == e['date'][0]})[0][e['date'][0]];
            setData(object);
            setInputData(object);
            setPauseData(pause_data);

            let member = isEmpty(object['member']) ? 0 : object['member'];
            let getMember = memberList.filter(x => { return x['id'] == member });
            if (getMember.length > 0) {
                onManageMember(getMember[0]);
            }

            setSaleType(object['sale_type']);
        }
        setIsOpen(false);
    }

    const onSaleTypeChange = (e: any) => {
        let object: any = data;
        setSaleType(e.target.value);

        object['sale_type'] = e.target.value;
        updateProduct(object);
    }

    const calculate = (object: any) => {
        let price = 0;
        let discount = 0;
        let vatAmt = 0;
        let total = 0;

        object.detail.forEach((x: any) => {
            price = price + parseFloat(x['total_price']);
            discount = discount + parseFloat(x['discount']);
        });
        setPrice(price + discount);
        setDiscount(discount);

        total = (price - object.discount_bill)
        vatAmt = object.vat == 1 ? parseFloat((total * 0.07).toFixed(2)) : 0;
        setVatAmount(vatAmt);
        setTotalPrice(total + vatAmt);
    }

    const onChargeMoney = () => {
        let object = JSON.parse(JSON.stringify(data));
        let product_price_status = true;
        object.detail.forEach((x: any) => {
            if (x['total_price'] <= 0) {
                product_price_status = false;
                return false;
            }
        });

        if (price > 0 && product_price_status) {
            setIsOpenCharge(true);
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'ราคาไม่ถูกต้อง')
            });
        }
    }

    const onValidateCharge= () => {
        if (receive < totalPrice) {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'จำนวนเงินไม่ถูกต้อง')
            });
        } else {
            onSave();
        }
    }

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(data));
        if (data.id > 0) {
            operation = 'UPDATE';
            key_id = data.id;
        }

        body['detail'] = productData;
        body['receive'] = paymentType == 2 ? totalPrice : receive;
        body['change'] = paymentType == 2 ? 0 : change;
        body['price'] = price;
        body['vat_amount'] = vatAmount;
        body['discount'] = discount;
        body['discount_bill'] = discountBill;
        if (body['detail'].length > 0 &&
            !isEmpty(body['receive']) && body['receive'] > 0 && !isEmpty(body['change']) && body['change'] >= 0 && !isEmpty(body['price']) && body['price'] > 0
            && !isEmpty(body['vat_amount']) && body['vat_amount'] >= 0 && !isEmpty(body['discount']) && body['discount'] >= 0 && !isEmpty(body['discount_bill']) && body['discount_bill'] >= 0) {
            body['vat'] = body['vat'] < 0 ? null : body['vat'];
            body['payment_type'] = paymentType;
            body['sale_type'] = saleType;
            body['point'] = isEmpty(body['point']) ? 0 : body['point'];
            body['remark'] = isEmpty(body['remark']) ? null : body['remark'];
            body['member'] = isEmpty(member.id) || member.id < 1 ? null : member.id;

            let res = await api_sale(operation, body, key_id);
            if (res === 1) {
                setIsOpenCharge(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { setDefaultValue(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        } 
    }

    const onPause = async () => {
        let body = JSON.parse(JSON.stringify(data));
        let pause_data = isEmpty(localStorage.getItem('pause')) ? [] : JSON.parse(localStorage.getItem('pause') || "[]");

        if (pause_data.length < 5) {
            if (body['detail'].length > 0) {
                let pause_data_obj: any = {}
                let date = convertFormatDateTime(new Date(), false)

                body['detail'] = productData;
                body['receive'] = paymentType == 2 ? totalPrice : receive;
                body['change'] = paymentType == 2 ? 0 : change;
                body['price'] = price;
                body['vat_amount'] = vatAmount;
                body['discount'] = discount;
                body['discount_bill'] = discountBill;
                body['vat'] = body['vat'] < 0 ? null : body['vat'];
                body['payment_type'] = paymentType;
                body['sale_type'] = saleType;
                body['point'] = isEmpty(body['point']) ? 0 : body['point'];
                body['remark'] = isEmpty(body['remark']) ? null : body['remark'];
                body['member'] = isEmpty(member.id) || member.id < 1 ? null : member.id;
                pause_data_obj[date] = body;
                pause_data.push(pause_data_obj);
                localStorage.setItem('pause', JSON.stringify(pause_data))

                setPauseData(pause_data);
                setDefaultValue();
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'รายการพักบิลถึงที่กำหนดแล้ว')
            });
        }
    }

    useEffect(() => {
        checkWorkShift();
        init();
    }, []);

    return (
        <>
            <div className='saleBox'>
                <div className='leftBox'>
                    <div className='memberBox'>
                        <div className='saleSearchBox'>
                            <div style={{width: "65%"}}>
                                <label>ค้นหาสมาชิก</label>
                                <Input name="product_code" value={memberCode} placeholder="รหัสสมาชิก" onChange={onMemberChange} onKeyUp={(e: any) => {onSearchInputChange(e, 'M')}} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('member')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>  
                            </div>
                            { member.id > 0 && <div style={{width: "35%", textAlign: 'right'}}>
                                <label>ชื่อ : <span style={{marginLeft: "20px"}}>{member.firstname}</span><span style={{margin: "0 20px"}}>{member.lastname}</span></label>
                                <Button className='btn-common' onClick={() => {setMember(initMember)}}><FontAwesomeIcon icon="times" /></Button>  
                            </div> }
                        </div>
                    </div>

                    <div className='productBox'>
                        <div className='conditionProductBox'>
                            <Radio.Group style={{display: 'flex', width: '100%'}} name="sale_type" value={saleType} onChange={onSaleTypeChange}>
                                {
                                    saleTypeList.map((obj: any) => {
                                        return <Radio key={obj.key} value={obj.id}>{obj.name}</Radio>
                                    })
                                }
                            </Radio.Group>
                            <div className='searchDataBox'>
                                <label>ค้นหาสินค้า</label>
                                <Input ref={productCodeRef} name="product_code" value={productCode} placeholder="รหัสสินค้า" onChange={onProductChange} onKeyUp={(e: any) => {onSearchInputChange(e, 'P')}} />
                                <label>จำนวน</label>
                                <Input name="product_qty" value={productQty} placeholder="" style={{width: '50px'}} onChange={onProductQtyChange} onKeyUp={(e: any) => {onSearchInputChange(e, 'P')}} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('product')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                        </div>
                        <CommonDataTable columns={productColumn} data={[...productData]} operation='OUT_TABLE' scollX={768} onChange={onActionProduct} />
                    </div>
                </div>
                <div className='rightBox'>
                    <div className='rightHeaderBox'>
                        <Radio.Group style={{display: 'flex', width: '100%', marginBottom: '20px'}} name="vat" value={vat} onChange={onDataChange}>
                            <Radio value={-1}>ไม่คิด</Radio>
                            <Radio value={0}>Vat out</Radio>
                            <Radio value={1}>Vat in</Radio>
                        </Radio.Group>
                        <Button className='btn-common' style={{width: '100%', marginBottom: '20px'}} onClick={() => {onOpenSelect('pause')}}>รายการพักบิล ({pauseData.length})</Button>
                        
                        <div className='chargeMoneyBox'>
                            <div className='formInput'>
                                <label>ราคา</label>
                                <Input name="price" value={price} placeholder={'กรุณาป้อนราคา'} disabled />
                            </div>
                            <div className='formInput'>
                                <label>ภาษีมูลค่าเพิ่ม</label>
                                <Input name="vat_amount" value={vatAmount} placeholder={'กรุณาป้อนภาษีมูลค่าเพิ่ม'} disabled />
                            </div>
                            <div className='formInput'>
                                <label>ส่วนลด</label>
                                <Input name="discount" value={discount} placeholder={'กรุณาป้อนส่วนลด'} disabled />
                            </div>
                            <div className='formInput'>
                                <label>ส่วนลดท้ายบิล</label>
                                <Input name="discount_bill" value={discountBill} placeholder={'กรุณาป้อนส่วนลดท้ายบิล'} onChange={onDataChange} />
                            </div>
                            <div className='formInputTotal'>
                                <label>รวม</label>
                                <Input name="total_price" value={totalPrice} placeholder={'กรุณาป้อนราคารวม'} disabled />
                            </div>
                            <Button className='btn-common' style={{width: '100%', height: '50px', fontSize: '1.2em', marginBottom: '20px'}} onClick={onChargeMoney}>คิดเงิน</Button>
                        </div>
                    </div>
                    
                    <div className='rightFooterBox'>
                        <div className='btn-sale-menu-box'>
                            <Button className='btn-back' onClick={onPause}>พักบิล</Button>
                            <Button className='btn-cancel-sale' onClick={setDefaultValue}>ยกเลิก</Button>
                        </div>
                        <div className='btn-sale-menu-box'>
                            <Button className='btn-common' onClick={()=>{ setIsOpenShortcut(true); }}>เมนูลัด</Button>
                            <Button className='btn-common' onClick={()=>{setLock(false); setIsOpenWorkShift(true);}}>เปิด - ปิดกะ</Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>

            <Modal
                closeIcon={false}
                centered
                open={isOpenCharge}
                footer={setFooterCharge()}
                width={'30%'}
                destroyOnClose={true}
            >
                <Radio.Group style={{display: 'flex', width: '100%', marginBottom: '20px'}} name="payment_type" value={paymentType} onChange={onChargeChange}>
                    {
                        paymentTypeList.map((obj: any) => {
                            return <Radio key={obj.key} value={obj.id}>{obj.name}</Radio>
                        })
                    }
                </Radio.Group>
                <div className='formInput'>
                    <label style={{fontSize: '20px'}}>ราคา</label>
                    <Input style={{fontSize: '20px'}} name="total_price" value={totalPrice} disabled />
                </div>
                <div className='formInput'>
                    <label style={{fontSize: '20px'}}>รับเงิน</label>
                    <Input style={{fontSize: '20px'}} name="receive" value={receive} onChange={onChargeChange} disabled={paymentType == 2} />
                </div>
                {paymentType == 1 ? <div className='formInput'>
                    <label style={{fontSize: '20px'}}>เงินทอน</label>
                    <Input style={{fontSize: '20px'}} name="change" value={change} onChange={onChargeChange} disabled />
                </div> : null}
                <div className='formInput'>
                    <label style={{fontSize: '20px'}}>หมายเหตุ</label>
                    <Input style={{fontSize: '20px'}} name="remark" value={remark} onChange={onChargeChange} />
                </div>
            </Modal>

            <Modal
                closeIcon={false}
                centered
                open={isOpenWorkShift}
                footer={null}
                width={'50%'}
                destroyOnClose={true}
            >
                <WorkShift lock={lock} onClosed={() => {setIsOpenWorkShift(false)}} onSaved={() => {setIsOpenWorkShift(false)}} />
            </Modal>

            <Modal
                closeIcon={false}
                centered
                open={isOpenShortcut}
                footer={setFooter()}
                width={'70%'}
                destroyOnClose={true}
            >
                <ShortCut onSelected={(e: any) => {onManageProduct('A', e); setIsOpenShortcut(false);}} />
            </Modal>
        </>
    );
}

export default Sale;