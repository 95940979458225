import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonDataTable from '../common/common-data-table';
import { api_product } from '../../api/product';
import CommonSearch from '../common/common-search';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { separate } from '../../model/separate';
import { api_config_separate } from '../../api/config';
import { isEmpty } from '../../utils/util';

interface props {
    separateData: separate[],
    onChange: React.Dispatch<React.SetStateAction<object>>
    onRemarkChange: React.Dispatch<React.SetStateAction<string>>
}

const SeparateDetailMulti: React.FC<props> = (prop) => {
    
    const productCodeRef = useRef<InputRef>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [data, setData] = useState<any>([]);
    const [separateData, setSeparateData] = useState([]);
    const [productList, setProductList] = useState([]);

    const [productCode, setProductCode] = useState('');
    const [productQty, setProductQty] = useState(1);
    const [remark, setRemark] = useState('');

    const productColumn: any = [
        { title: 'รหัสสินค้าต้นทาง', dataIndex: 'from_product_code', key: 'from_product_code', width: '100px', fixed: 'left', edit: false },
        { title: 'ชื่อสินค้าต้นทาง', dataIndex: 'from_product_name', key: 'from_product_name', width: '160px', fixed: 'left', edit: false },
        { title: 'จำนวน', dataIndex: 'from_quantity', key: 'from_quantity', width: '60px', type: 'INPUT', edit: true },
        { title: 'หน่วย', dataIndex: 'from_product_unit_name', key: 'from_product_unit_name', width: '60px', edit: false },
        { title: 'ต้นทุนต้นทาง', dataIndex: 'from_product_cost', key: 'from_product_cost', width: '80px', edit: false },
        { title: 'รหัสสินค้าปลายทาง', dataIndex: 'to_product_code', key: 'to_product_code',  width: '120px', edit: false },
        { title: 'ชื่อสินค้าปลายทาง', dataIndex: 'to_product_name', key: 'to_product_name',  width: '160px', edit: false },
        { title: 'จำนวน', dataIndex: 'to_quantity', key: 'to_quantity', width: '60px', edit: false },
        { title: 'ราคาขายเดิม', dataIndex: 'price', key: 'price', width: '80px', edit: false },
        { title: 'ราคาขาย', dataIndex: 'to_product_price', key: 'to_product_price', type: 'INPUT', width: '100px', edit: true },
        { title: 'หน่วย', dataIndex: 'to_product_unit_name', key: 'to_product_unit_name', width: '60px', edit: false },
    ]

    const init = async () => {
        setData(prop.separateData);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setSeparateData(object != null ? object : []);
    }

    const onRemarkChange = (e: any) => {
        setRemark(e.target.value);
        prop.onRemarkChange(remark);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
        
    }

    const onProductChange = (e: any) => {
        setProductCode(e.target.value);
    }

    const onProductQtyChange = (e: any) => {
        setProductQty(isEmpty(e.target.value) ? 0 : parseInt(e.target.value));
    }

    const onSearchInputChange = (e: any) => {
        if (e.keyCode == 13) {
            let code = e.target.value;
            if (e.target.name == 'product_qty') {
                code = productCode;
            }
            
            let getProduct = productList.filter(x => { return x['product_code'] == code });
            if (getProduct.length > 0) {
                onManageProduct('A', getProduct[0]);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });
            }
            
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        } else if (e.table == 'vendor') {
            onDataChange( {
                target: {
                    name: 'vendor',
                    value: e.id
                }
            })

            onDataChange( {
                target: {
                    name: 'vendor_name',
                    value: e.name
                }
            })
        }

        setIsOpen(false);
    }

    const onManageProduct = async (key: string, e: any) => {
        let object: any = data;

        if (key == 'A') {
            if (object.filter((x: any) => { return x['from_product'] == e['id']}).length > 0) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้อยู่ในรายการแล้ว')
                });
            } else {
                let getSeparate = await api_config_separate('GET_BY_VALUE', {}, e['id']);
                if (isEmpty(getSeparate['to_product']) || getSeparate['to_product'] <= 0) {
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'สินค้านี้ไม่มีการตั้งค่าแยกแพค')
                    }); 
                } else {
                    object = [
                        ...object,
                        {
                            "key": (object.length + 1),
                            "from_product": e['id'],
                            "from_product_code": e['product_code'],
                            "from_product_name": e['product_name'],
                            "from_product_unit_name": e['product_unit_name'],
                            "from_product_cost": e['product_cost'],
                            "from_quantity": isEmpty(productQty) || productQty <= 0 ? 1 : productQty,
                            "to_product": getSeparate['to_product'],
                            "to_product_code": getSeparate['to_product_code'],
                            "to_product_name": getSeparate['to_product_name'],
                            "to_product_unit_name": getSeparate['to_product_unit_name'],
                            "to_product_price": getSeparate['price_retail'],
                            "price": getSeparate['price_retail'],
                            "config_to_quantity": getSeparate['config_to_quantity'],
                            "to_quantity": isEmpty(getSeparate['to_quantity']) ? '0' : ((isEmpty(productQty) || productQty <= 0 ? 1 : productQty) * getSeparate['to_quantity'])
                        }
                    ];
                    
                    setData(object);
                    setInputData(object);
                    prop.onChange(object);
                }
            }
        } else if (key == 'E') {
            var index = object.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object[index] = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setProductQty(1);
    }

    const onActionProduct = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let detail: any = [];

                object.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        detail.push(x);
                    }
                });
                object = detail;

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else {
            object = e;
            object.forEach((obj: any) => {
                obj['to_quantity'] = (obj['from_quantity'] || 0) * (obj['config_to_quantity'] || 0);
            });

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }

    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox' style={{'maxHeight': '50%'}}>
                    <div className='searchDataBox'>
                        <label>ค้นหาสินค้า</label>
                        <Input ref={productCodeRef} name="product_code" value={productCode} placeholder="รหัสสินค้า" onChange={onProductChange} onKeyUp={onSearchInputChange} />
                        <label>จำนวน</label>
                        <Input name="product_qty" value={productQty} placeholder="" style={{width: '50px'}} onChange={onProductQtyChange} onKeyUp={onSearchInputChange} />
                        <Button className='btn-common' onClick={() => {onOpenSelect('product')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                    </div>
                    <CommonDataTable columns={productColumn} data={[...separateData]} operation='OUT_TABLE' scollX={2000} onChange={onActionProduct} />
                </div>
                <div className='formInput' style={{justifyContent: "flex-end", marginTop: "20px"}}>
                    <label>หมายเหตุ</label>
                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onRemarkChange} style={{marginLeft: "10px"}} />
                </div>
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default SeparateDetailMulti;