import React, { useState, useEffect, useRef } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty } from '../../utils/util';
import { Table, Input, Button, InputRef } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { api_product } from '../../api/product';
import { api_vendor } from '../../api/vendor';
import { api_member } from '../../api/member';

interface props {
    table: string,
    onSelect: React.Dispatch<React.SetStateAction<object>>
}

const CommonSearch: React.FC<props> = (prop) => {

    const searchRef = useRef<InputRef>(null);
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [filterField, setFilterField] = useState<string[]>([]);

    const onSetColumn = () => { 
        let field: any = []
        if (prop.table.toUpperCase() == 'PRODUCT') {
            field = [
                { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code' },
                { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name' }
            ]
        } else if (prop.table.toUpperCase() == 'VENDOR') {
            field = [
                { title: 'รหัสผู้ขายส่งสินค้า', dataIndex: 'code', key: 'code' },
                { title: 'ชื่อผู้ขายส่งสินค้า', dataIndex: 'name', key: 'name' }
            ]
        } else if (prop.table.toUpperCase() == 'PURCHASE_ORDER') {
            field = [
                { title: 'เลขที่ใบสั่งซื้อ', dataIndex: 'document_no', key: 'document_no' }
            ]
        } else if (prop.table.toUpperCase() == 'MEMBER') {
            field = [
                { title: 'รหัสสมาชิก', dataIndex: 'code', key: 'code' },
                { title: 'ชื่อ', dataIndex: 'firstname', key: 'firstname' },
                { title: 'นามสกุล', dataIndex: 'lastname', key: 'lastname' }
            ]
        } else if (prop.table.toUpperCase() == 'PAUSE') {
            field = [
                { title: 'วันที่', dataIndex: 'date', key: 'date' },
            ]
        }
        
        let col: ColumnsType<any> = [
            ...field,
            ...[{ title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_: any, record: any) =>
                data.length >= 1 ? 
                    prop.table.toUpperCase() == 'PAUSE' ? (
                        <div>
                            <Button className='btn-common' type="primary" onClick={() => {prop.onSelect({...{table: prop.table}, ...record})}}>
                                เลือก
                            </Button>
                            <Button className='btn-common' type="primary" style={{marginLeft: '5px'}} onClick={() => {onRemovePause(record)}}>
                                ลบ
                            </Button>
                        </div>
                    ) :
                    ( 
                        <Button className='btn-common' type="primary" onClick={() => {
                            setSearch('');
                            searchRef.current?.focus();
                            prop.onSelect({...{table: prop.table}, ...record});
                        }}>
                            เลือก
                        </Button>
                    )
                : null
            }]
        ]

        return col;
    }

    const onRemovePause = (record: any) => {
        let pause_data: any = JSON.parse(localStorage.getItem('pause') || "[]");
        pause_data = pause_data.filter((x: any) => { return Object.keys(x) != record['date'][0] });
        if (pause_data.length <= 0) {
            prop.onSelect({table: prop.table, empty: true});
        } else {
            localStorage.setItem('pause', JSON.stringify(pause_data));
            setData(pause_data);
        }
    }

    const init = async () => {
        let getData = [];
        if (prop.table.toUpperCase() == 'PRODUCT') {
            getData = await api_product('LIST', {});
            setFilterField(["product_code", "product_name"]);
        } else if (prop.table.toUpperCase() == 'VENDOR') {
            getData = await api_vendor('LIST', {});
            setFilterField(["code", "name"]);
        } else if (prop.table.toUpperCase() == 'PURCHASE_ORDER') {
            getData = []
            setFilterField(["document_no"]);
        } else if (prop.table.toUpperCase() == 'MEMBER') {
            getData = await api_member('GET_BY_CONDITION', {});
            setFilterField(["code", "firstname", "lastname"]);
        } else if (prop.table.toUpperCase() == 'PAUSE') {
            let pause_data: any = JSON.parse(localStorage.getItem('pause') || "[]");
            pause_data.forEach((x: any, index: Number) => {
                getData.push({key: index, 'date': Object.keys(x)})
            });
            setFilterField([]);
        }

        if (getData.length > 0) {
            setData(getData);
        }
        searchRef.current?.focus();
    }

    const onSearch = (event: any) => {
        setSearch(event.target.value);
        onFilter();
    }

    const onFilter = () => {
        let filterData = data;
        let condition = "";

        filterField.forEach(key => {
            condition += `((String(x['${key}'])).indexOf(search.toUpperCase()) > -1) || `;
        });

        if (condition !== '') {
            const lastIndex = condition.lastIndexOf('||');
            filterData = filterData.filter((x) => { 
                return eval(condition.slice(0, lastIndex));
            });
        }

        return isEmpty(search) ? data : filterData;
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <div className='filterBox'>
                { prop.table.toUpperCase() != 'PAUSE' ? <div className='searchBox'>
                    <label>ค้นหา</label><QuestionCircleOutlined rev={undefined} />
                    <Input 
                        ref={searchRef}
                        className='uppercase'
                        placeholder='ค้นหา'
                        value={search}
                        onChange={onSearch}
                    />
                </div> : null }
            </div>
            <Table columns={onSetColumn()} dataSource={onFilter()} pagination={false} scroll={{ x: 600, y: window.innerHeight - 322 }} />
        </>
    );
}

export default CommonSearch;