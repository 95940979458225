import axios from 'axios';
import { base_url } from './api-config'; 

const getHeaders = (operation: string, key_id?: number) => {
    let header: any = {
        "Content-Type": "application/json",
        "operation": operation
    }
    if (key_id && key_id != undefined && key_id != null) {
        header["key-id"] = key_id
    }

    return { 
        headers: header
    }
}

export const api_product = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/product`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        });

    return result;
}

export const api_product_type = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/product/type`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        });

    return result;
}

export const api_product_unit = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/product/unit`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        });

    return result;
}

export const api_good_receive = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/good/receive`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}

export const api_check_stock = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/check/stock`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}

export const api_stock = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/stock`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}

export const api_withdraw = async (operation: string, body: any, key_id?: number) => {
    let result: any = [];

    await axios.post(`${base_url}/api/v1/withdraw`, JSON.stringify(body), getHeaders(operation, key_id))
        .then(res => {
            if (res && res.status == 200) {
                result = res.data;
            }
        }).catch(e => {})

    return result;
}
