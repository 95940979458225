export interface memberGroup {
    key: number,
    id: number,
    name: string,
    status: number,
    create_date: string,
    members: member[],
    members_count?: number
}

export const initMemberGroup = {
    key: 0,
    id: 0,
    name: "",
    status: 1,
    create_date: "",
    members: [],
    members_count: 0
}


export interface member {
    key: number,
    id: number,
    code: string,
    firstname: string,
    lastname?: string,
    tel: string,
    email?: string,
    tax_id?: string,
    address?: string,
    remark?: string,
    member_group: number,
    member_group_name?: string,
    status: number,
    create_date: string
}

export const initMember = {
    key: 0,
    id: 0,
    code: "",
    firstname: "",
    lastname: "",
    tel: "",
    email: "",
    tax_id: "",
    address: "",
    remark: "",
    member_group: 0,
    member_group_name: "",
    status: 1,
    create_date: ""
}
