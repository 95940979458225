export interface separate {
    key: number,
    id: number,
    from_product: number,
    to_product: number,
    from_quantity: number,
    to_quantity: number,
    config_to_quantity: number,
    status: number,
    remark: string,
    create_date: string,
    from_product_code: string,
    from_product_name: string,
    from_product_unit_name: string,
    from_product_cost: number,
    to_product_code: string,
    to_product_name: string,
    to_product_unit_name: string,
    to_product_cost: number,
}

export const initSeparate = {
    key: 0,
    id: 0,
    from_product: 0,
    to_product: 0,
    from_quantity: 0,
    to_quantity: 0,
    config_to_quantity: 0,
    status: 0,
    remark: "",
    create_date: "",
    from_product_code: "",
    from_product_name: "",
    from_product_unit_name: "",
    from_product_cost: 0,
    to_product_code: "",
    to_product_name: "",
    to_product_unit_name: "",
    to_product_cost: 0,
}