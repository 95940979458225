import React, { useEffect, useRef, useState } from 'react';
import { api_shortcut } from '../../api/sale';

interface props {
    onSelected: React.Dispatch<React.SetStateAction<object>>
}

const Shortcut: React.FC<props> = (prop) => {

    const [data, setData] = useState([]);

    const init = async () => {
        let getShortcut = await api_shortcut('LIST', {});
        setData(getShortcut);
    }

    const selectProeuduct = (object: any) => {
        prop.onSelected(object);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <div className='shortcutContainer'>
                {
                    data.map((obj: any) => {
                        return <div key={obj.key} onClick={() => {selectProeuduct(obj)}}>
                            <label>{obj.product_code}</label>
                            <label>{obj.product_name}</label>
                        </div>
                    })
                }
            </div>
        </>
    )
}

export default Shortcut;