import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { separate } from '../../model/separate';
import { api_product } from '../../api/product';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonSearch from '../common/common-search';
import { api_config_separate } from '../../api/config';
import { isEmpty } from '../../utils/util';

interface props {
    separateData: separate,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const SeparateDetail: React.FC<props> = (prop) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tableSelect, setTableSelect] = useState('');
    const [flag, setFlag] = useState('');

    const [data, setData] = useState({});
    const [productList, setProductList] = useState([]);

    const [fromProductCode, setFromProductCode] = useState('');
    const [fromProductName, setFromProductName] = useState('');
    const [fromQuantity, setFromQuantity] = useState(0);
    const [fromProductUnitName, setFromProductUnitName] = useState('');
    const [fromProductCost, setFromProductCost] = useState(0);

    const [toProductCode, setToProductCode] = useState('');
    const [toProductName, setToProductName] = useState('');
    const [toProductPrice, setToProductPrice] = useState(0);
    const [toQuantity, setToQuantity] = useState(0);
    const [configToQuantity, setConfigToQuantity] = useState(0);
    const [toProductUnitName, setToProductUnitName] = useState('');
    const [toProductCost, setToProductCost] = useState(0);
    const [remark, setRemark] = useState('');

    const init = async () => {
        setData(prop.separateData);
        setInputData(prop.separateData);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);

        if (prop.separateData.id > 0) {
            let object: any = prop.separateData;
            let from: any = getProduct.filter((x: any) => { return x['id'] == object['from_product'] });
            let to: any = getProduct.filter((x: any) => { return x['id'] == object['to_product'] });

            object['from_product_cost'] = from[0]['product_cost'];
            object['to_product_price'] = to[0]['price_retail'];
            setData(object);
            setInputData(object);
        }
    }

    const initSeparate = () => {
        let object: any = {};
        object['from_product'] = 0;
        object['from_product_code'] = '';
        object['from_product_name'] = '';
        object['from_product_unit_name'] = '';
        object['from_product_cost'] = 0;
        object['from_quantity'] = 0;
        
        object['to_product'] = 0;
        object['to_product_code'] = '';
        object['to_product_name'] = '';
        object['to_product_unit_name'] = '';
        object['to_product_cost'] = 0;
        object['to_product_price'] = 0;
        object['config_to_quantity'] = 0;
        object['to_quantity'] = 0;

        return object;
    } 

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setFromProductCode(object.from_product_code != null ? object.from_product_code : '');
        setFromProductName(object.from_product_name != null ? object.from_product_name : '');
        setFromQuantity(object.from_quantity != null ? object.from_quantity : '');
        setFromProductUnitName(object.from_product_unit_name != null ? object.from_product_unit_name : '');
        setFromProductCost(object.from_product_cost != null ? object.from_product_cost : '');

        setToProductCode(object.to_product_code != null ? object.to_product_code : '');
        setToProductName(object.to_product_name != null ? object.to_product_name : '');
        setToProductPrice(object.to_product_price != null ? object.to_product_price : '');
        setToQuantity(object.to_quantity != null ? object.to_quantity : '');
        setConfigToQuantity(object.config_to_quantity != null ? object.config_to_quantity : '');
        setToProductUnitName(object.to_product_unit_name != null ? object.to_product_unit_name : '');
        setRemark(object.remark != null ? object.remark : '');
        calculateToProductCost(object);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        if (key == 'from_quantity') {
            object['to_quantity'] = calculateToProduct(value);
        }
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const calculateToProduct = (value: number) => {
        return ((isEmpty(value) ? 0 : value) * configToQuantity)
    }

    const calculateToProductCost = (object: any) => {
        let from_cost = isEmpty(object['from_product_cost']) ? 0 : parseFloat(object['from_product_cost'])
        let to_qty = isEmpty(object['config_to_quantity']) ? 0 : parseFloat(object['config_to_quantity'])
        let total = from_cost / to_qty;
        setToProductCost(Number.isNaN(total) ? 0 : total);
    }

    const onSearchInputChange = (e: any, sourceFlag: string) => {
        if (e.keyCode == 13) {
            let getProduct = productList.filter(x => { return x['product_code'] == e.target.value });
            if (getProduct.length > 0) {
                setFlag(sourceFlag);
                onManageProduct('A', getProduct[0], sourceFlag);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });

                if (sourceFlag == 'F') {
                    setFromProductCode('');
                } else {
                    setToProductCode('');
                }
            }
        }
    }

    const onManageProduct = async (key: string, e: any, sourceFlag?: string) => {
        let object: any = data;
        let sourceValue: string | undefined = flag;
        if (!isEmpty(sourceFlag)) {
            sourceValue = sourceFlag
        }

        if (key == 'A') {
            if (sourceValue == 'F') {
                object['from_product'] = e['id'];
                object['from_product_code'] = e['product_code'];
                object['from_product_name'] = e['product_name'];
                object['from_product_unit_name'] = e['product_unit_name'];
                object['from_product_cost'] = e['product_cost'];
                object['from_quantity'] = '1';

                let getSeparate = await api_config_separate('GET_BY_VALUE', {}, e['id']);
                if (isEmpty(getSeparate['to_product']) || getSeparate['to_product'] <= 0) {
                    object = initSeparate();
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'สินค้านี้ไม่มีการตั้งค่าแยกแพค')
                    });
                } else {
                    let getProduct: any = productList.filter(x => { return x['id'] == getSeparate['to_product'] });
                    object['to_product'] = getSeparate['to_product'];
                    object['to_product_code'] = getSeparate['to_product_code'];
                    object['to_product_name'] = getSeparate['to_product_name'];
                    object['to_product_unit_name'] = getSeparate['to_product_unit_name'];
                    object['to_product_price'] = getProduct[0]['price_retail'];
                    object['config_to_quantity'] = getSeparate['config_to_quantity'];
                    object['to_quantity'] = getSeparate['to_quantity'];
                }
            }

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setFlag('');
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        }

        setIsOpen(false);
    }

    const onOpenSelect = (table: string, flag: string) => {
        setFlag(flag);
        setTableSelect(table);
        setIsOpen(true);
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }
   
    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='productFrom' style={{marginTop: '20px'}}>
                    <label className='title'>สินค้าต้นทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า <span>*</span></label>
                                <Input name="from_product_code" value={fromProductCode} placeholder="รหัสสินค้า" onChange={onDataChange} onKeyUp={(e) => { onSearchInputChange(e, 'F') }} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('product', 'F')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="from_product_name" value={fromProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน <span>*</span></label>
                                <Input name="from_quantity" value={fromQuantity} placeholder="จำนวน" onChange={onDataChange} />
                                <span>{fromProductUnitName}</span>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ต้นทุน</label>
                                <Input name="from_product_cost" value={fromProductCost} placeholder="ต้นทุน" disabled />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="mouse-scroll">
                    <div>
                        <span className="down-arrow-1"></span>
                        <span className="down-arrow-2"></span>
                        <span className="down-arrow-3"></span>
                    </div>
                </div> */}

                <div className='productTo' style={{marginTop: '10%'}}>
                    <label className='title'>สินค้าปลายทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า</label>
                                <Input name="to_product_code" value={toProductCode} placeholder="รหัสสินค้า" disabled />
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="to_product_name" value={toProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน</label>
                                <Input name="to_quantity" value={toQuantity} placeholder="จำนวน" disabled />
                                <span>{toProductUnitName}</span>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ราคาต่อชิ้น</label>
                                <Input name="to_product_price" value={toProductPrice} placeholder="ราคาต่อชิ้น" onChange={onDataChange} />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ต้นทุนต่อชิ้น</label>
                                <Input name="to_product_cost" value={toProductCost} placeholder="ต้นทุนต่อชิ้น" onChange={onDataChange} disabled />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='formInput' style={{justifyContent: "flex-end", marginTop: "20px"}}>
                    <label>หมายเหตุ</label>
                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onDataChange} style={{marginLeft: "10px"}} />
                </div>

            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default SeparateDetail;
