import React, { useEffect, useState } from 'react';
import { member, memberGroup } from '../../model/member';
import { Button, Input, Modal, Select } from 'antd';
import { api_member, api_member_group } from '../../api/member';
import { filterOption } from '../../utils/select';
import { getDateFromDatePicker } from '../../utils/datepicker';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonDataTable from '../common/common-data-table';
import CommonSearch from '../common/common-search';

interface props {
    memberGroup: memberGroup,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const { Option } = Select;

const MemberGroupDetail: React.FC<props> = (prop) => {

    const [data, setData] = useState<any>({});
    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [name, setName] = useState('');
    const [memberCode, setMemberCode] = useState('');
    const [memberData, setMemberData] = useState([]);
    const [memberList, setMemberList] = useState<member[]>([]);

    const memberColumn: any = [
        { title: 'รหัสสมาชิก', dataIndex: 'code', key: 'code', edit: false },
        { title: 'ชื่อ', dataIndex: 'firstname', key: 'firstname', edit: false },
        { title: 'นามสกุล', dataIndex: 'lastname', key: 'lastname', edit: false },
    ]

    const init = async () => {
        let getMember = await api_member('LIST', {})
        setMemberList(getMember)

        if (prop.memberGroup.id > 0) {
            setIsUpdate(true);
            setMemberData([]);
            let getMemberGroup = await api_member_group('GET', {}, prop.memberGroup.id);
            setData(getMemberGroup);
            setInputData(getMemberGroup);
            prop.onChange(getMemberGroup);
        }
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setName(object.name);
        setMemberData(object.members != null ? object.members : []);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onMemberChange = (e: any) => {
        setMemberCode(e.target.value);
    }

    const onSearchInputChange = (e: any) => {
        if (e.keyCode == 13) {
            let getMember = memberList.filter(x => { return x['code'] == e.target.value });
            if (getMember.length > 0) {
                onManageMember('A', getMember[0]);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสมาชิกที่ค้นหา')
                });
            }
            
            setMemberCode('');
        }
    }

    const onManageMember = (key: string, e: any) => {
        let object: any = data;

        if (key == 'A') {
            if (object.members.filter((x: any) => { return x['id'] == e['id']}).length > 0) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'มีสมาชิกนี้อยู่ในรายการแล้ว')
                });
            } else {
                object.members = [
                    ...object.members,
                    ...[{
                        id: e['id'],
                        code: e['code'],
                        firstname: e['firstname'],
                        lastname: e['lastname'],
                        tel: e['tel'],
                        email: e['email'],
                        tax_id: e['tax_id'],
                        address: e['address'],
                        remark: e['remark'],
                        member_group: e['member_group'],
                        member_group_name: e['member_group_name'],
                        status: e['status'],
                        create_date: e['create_date']
                    }]
                ];

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else if (key == 'E') {
            var index = object.members.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object.members[index] = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const onActionMembers = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let members: any = [];

                object.members.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        members.push(x);
                    }
                });
                object.members = members;

                setData(object);
                setInputData(object);
                prop.onChange(object);
            }
        } else {
            object.members = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'member') {
            onManageMember('A', e);
        }
        setIsOpen(false);
    }
    
    useEffect(() => {
        init();
        setData(prop.memberGroup);
        setInputData(prop.memberGroup);
    }, []);


    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox'>
                    <div className='InputBox'>
                        <div className='formInput'>
                            <label>ชื่อ<span> *</span></label>
                            <Input name="name" value={name} placeholder={'กรุณาป้อนชื่อ'} onChange={onDataChange} />
                        </div>
                        <div className='detailBox' style={{'marginBottom': '50px', 'marginTop': '50px', 'maxHeight': '50%'}}>
                            <div className='searchDataBox'>
                                <label>ค้นหาสมาชิก</label>
                                <Input name="product_code" value={memberCode} placeholder="รหัสสมาชิก" onChange={onMemberChange} onKeyUp={onSearchInputChange} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('member')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                            <CommonDataTable columns={memberColumn} data={[...memberData]} operation='OUT_TABLE' scollX={575} onChange={onActionMembers} />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default MemberGroupDetail;