import React, { useEffect, useState } from 'react';
import CommonDetail from '../common/common-detail';
import CommonTableList from '../common/common-table-list';
import IncludeDetail from './include-detail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { api_include } from '../../api/include';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { include, initInclude } from '../../model/include';
import { Modal } from 'antd';
import { isEmpty } from '../../utils/util';
// import IncludeDetailMulti from './include-detail-multi';

const Include: React.FC = (prop) => {
    
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [include, setInclude] = useState<include>(initInclude);
    const [includeMulti, setIncludeMulti] = useState<include[]>([initInclude]);
    const [remark, setRemark] = useState('');

    const filterField: any = ['create_date', 'from_product_name', 'to_product_name'];

    const placeholder_txt: string = 'วันที่ / สินค้าต้นทาง / สินค้าปลายทาง' 

    const columns: ColumnsType<any> = [
        { title: 'วันที่', dataIndex: 'create_date', key: 'create_date', width: '200px', },
        { title: 'สินค้าต้นทาง', dataIndex: 'from_product_name', key: 'from_product_name' },
        { title: 'จำนวนสินค้าต้นทาง', dataIndex: 'from_quantity', key: 'from_quantity', render: (_, record) =>
            data.length >= 1 ? ( 
                <label>{record.from_quantity} {record.from_product_unit_name}</label>
            ) : null
        },
        { title: 'สินค้าปลายทาง', dataIndex: 'to_product_name', key: 'to_product_name' },
        { title: 'จำนวนสินค้าปลายทาง', dataIndex: 'to_quantity', key: 'to_quantity', render: (_, record) =>
            data.length >= 1 ? ( 
                <label>{record.to_quantity} {record.to_product_unit_name}</label>
            ) : null
        },
        { title: '', dataIndex: 'operation', width: '60px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    {/* <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} /> */}
                    {/* <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} /> */}
                    {/* <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} /> */}
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getInclude = await api_include('LIST', {});
        setData(getInclude);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
        }
    }

    const onShowDetail = (mode: string, includeData?: include) => {
        if (mode === 'MULTI') {
            setTitle('รวมแพคหลายรายการ')
            setIncludeMulti([]);
        } else {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('รวมแพค')
            } else if (mode === 'EDIT') {
                setTitle('แก้ไขการรวมแพค')    
            } else if (mode === 'VIEW') {
                setTitle('รายละเอียดการรวมแพค')
            }
            setInclude(includeData !== undefined && includeData !== null ? includeData : initInclude);
        }

        setMode(mode);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(include));
        
        if (mode == 'MULTI') {
            let isPass = true;
            includeMulti.forEach((object: any) => {
                if (isEmpty(object['from_product']) || isEmpty(object['to_product']) || isEmpty(object['from_quantity'])
                    || isEmpty(object['to_quantity']) || object['from_quantity'] <= 0 || object['to_quantity'] <= 0) {
                    isPass = false;
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
                    });
                    
                    return false;
                }
            });

            if (isPass) {
                operation = 'INSERT_MULTI';
                body = {
                    "include": includeMulti,
                    "remark": remark
                }
                let res = await api_include(operation, body, key_id);
                if (res === 1) {
                    setOpen(false);
                    Modal.success({
                        ...getConfigModal('สำเร็จ', 'เพิ่มข้อมูลสำเร็จแล้ว'),
                        ...{ onOk() { init(); }}
                    });
                } else {
                    Modal.error({
                        ...getConfigModal('ผิดพลาด', 'เพิ่มข้อมูลไม่สำเร็จ'),
                    });
                }
            }
        } else {
            if (include.id > 0) {
                operation = 'UPDATE';
                key_id = include.id;
            }

            if (!Number.isInteger(parseInt(body['from_quantity']) / body['config_to_quantity'])) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'กรุณาป้อนจำนวนสินค้าให้ถูกต้อง')
                });
            } else if (!isEmpty(body['from_product']) && !isEmpty(body['from_quantity']) && !isEmpty(body['to_product']) && !isEmpty(body['to_quantity'])) {
                body['from_product'] = isEmpty(body['from_product']) ? 0 : body['from_product'];
                body['from_quantity'] = isEmpty(body['from_quantity']) ? 0 : body['from_quantity'];
                body['to_product'] = isEmpty(body['to_product']) ? 0 : body['to_product'];
                body['to_quantity'] = isEmpty(body['to_quantity']) ? 0 : body['to_quantity'];
                body['remark'] = isEmpty(body['remark']) ? null : body['remark'];

                let res = await api_include(operation, body, key_id);
                if (res === 1) {
                    setOpen(false);
                    Modal.success({
                        ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                        ...{ onOk() { init(); }}
                    });
                } else {
                    Modal.error({
                        ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                    });
                }
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
                });
            }
        }
    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_include('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setInclude(payload)
    }

    const onDataDetailMultiChange = (payload: any) => {
        setIncludeMulti(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='good-receive' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} onAddMulti={() => { onShowDetail('MULTI') }} /> : null } */}
            <CommonTableList page='include' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} onAddMulti={() => { onShowDetail('MULTI') }} />
            <CommonDetail width={mode == 'MULTI' ? '90%' : '70%'} mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} 
                element={ 
                    // mode == 'MULTI' ? 
                    // <IncludeDetailMulti includeData={[...includeMulti]} onChange={onDataDetailMultiChange} onRemarkChange={setRemark} /> : 
                    <IncludeDetail includeData={{...include}} onChange={onDataDetailChange} /> 
                }
            />
        </>
    );
}

export default Include;