export interface goodReceive {
    key: number,
    id: number,
    document_no: string,
    quantity: number,
    weight: number,
    cost: number,
    remark: string,
    status: number,
    vendor: number,
    vendor_name: string,
    purchase_order: number,
    purchase_order_doc_no: string,
    create_date: string,
    update_date: string,
    create_by_name: string,
    detail: goodReceiveDetail[]
}

export interface goodReceiveDetail {
    key: number,
    id: number,
    good_receive: number,
    quantity: number,
    weight: number,
    cost: number,
    lot: string,
    expire_date: string,
    remark: string,
    status: number,
    product: number,
    vat: number,
    product_code: string,
    product_name: string,
    product_name_other: string,
    product_type: number,
    product_unit: number,
}

export const initGoodReceive = {
    key: 0,
    id: 0,
    document_no: "",
    quantity: 0,
    weight: 0,
    cost: 0,
    remark: "",
    status: 0,
    vendor: 0,
    vendor_name: "",
    purchase_order: 0,
    purchase_order_doc_no: "",
    create_date: "",
    update_date: "",
    create_by_name: "",
    detail: []
}

export const initGoodReceiveDetail = {
    key: 0,
    id: 0,
    good_receive: 0,
    quantity: 0,
    weight: 0,
    cost: 0,
    lot: "",
    expire_date: "",
    remark: "",
    status: 0,
    product: 0,
    vat: 0,
    product_code: "",
    product_name: "",
    product_name_other: "",
    product_type: 0,
    product_unit: 0,
}