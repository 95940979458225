import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import userReducer from "./user/slice";
// import productReducer from "./product/slice";
import loadingReducer from "./loading/slice";

const reducer = {
  userReducer,
  // productReducer,
  loadingReducer
};

export const store = configureStore({
  reducer
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();