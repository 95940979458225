import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, InputRef, Modal } from 'antd';
import { configSeparate } from '../../../model/config';
import { api_product } from '../../../api/product';
import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonSearch from '../../common/common-search';
import { api_config_separate } from '../../../api/config';
import { isEmpty } from '../../../utils/util';

interface props {
    mode: string,
    configSeparate: configSeparate,
    onChange: React.Dispatch<React.SetStateAction<object>>,
    onSaved: boolean,
}

const ConfigSeparateDetail: React.FC<props> = (prop) => {

    const destCode = useRef<InputRef>(null);
    const destQty = useRef<InputRef>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isContinue, setIsContinue] = useState(false);
    const [tableSelect, setTableSelect] = useState('');
    const [flag, setFlag] = useState('');

    const [data, setData] = useState({});
    const [productList, setProductList] = useState([]);

    const [fromProductCode, setFromProductCode] = useState('');
    const [fromProductName, setFromProductName] = useState('');
    const [fromQuantity, setFromQuantity] = useState(0);
    const [fromProductUnitName, setFromProductUnitName] = useState('');

    const [toProductCode, setToProductCode] = useState('');
    const [toProductName, setToProductName] = useState('');
    const [toQuantity, setToQuantity] = useState(0);
    const [toProductUnitName, setToProductUnitName] = useState('');

    const init = async () => {
        setData(prop.configSeparate);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);

        if (prop.configSeparate.id > 0) {
            setIsUpdate(true);
            setData(prop.configSeparate);
            setInputData(prop.configSeparate);
        }
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setFromProductCode(object.from_product_code != null ? object.from_product_code : '');
        setFromProductName(object.from_product_name != null ? object.from_product_name : '');
        setFromQuantity(object.from_quantity != null ? object.from_quantity : '0');
        setFromProductUnitName(object.from_product_unit_name != null ? object.from_product_unit_name : '');
        setToProductCode(object.to_product_code != null ? object.to_product_code : '');
        setToProductName(object.to_product_name != null ? object.to_product_name : '');
        setToQuantity(object.to_quantity != null ? object.to_quantity : '0');
        setToProductUnitName(object.to_product_unit_name != null ? object.to_product_unit_name : '');
        setIsContinue(object.is_continue);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onSearchInputChange = (e: any, sourceFlag: string) => {
        if (e.keyCode == 13) {
            let getProduct = productList.filter(x => { return x['product_code'] == e.target.value });
            if (getProduct.length > 0) {
                setFlag(sourceFlag);
                onManageProduct('A', getProduct[0], sourceFlag);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });

                if (flag == 'F') {
                    setFromProductCode('');
                } else {
                    setToProductCode('');
                }
            }
        }
    }

    const onManageProduct = async (key: string, e: any, sourceFlag?: string) => {
        let object: any = data;
        let sourceValue: string | undefined = flag;
        if (!isEmpty(sourceFlag)) {
            sourceValue = sourceFlag
        }

        if (key == 'A') {
            if ((sourceValue == 'F' && (e['id'] == object['to_product'])) || (sourceValue == 'T' && (e['id'] == object['from_product']))) {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้อยู่ในรายการแล้ว')
                });
            } else {
                let getSeparate: any = {}
                if (sourceValue == 'F') {
                    getSeparate = await api_config_separate('GET_BY_VALUE', {}, e['id']);
                }

                if (getSeparate['id'] > 0) {
                    object['from_product'] = 0;
                    object['from_product_code'] = "";
                    object['from_product_name'] = "";
                    object['from_product_unit_name'] = "";
                    object['from_quantity'] = 0;
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'มีสินค้านี้มีการตั้งค่าแยกแพคแล้ว')
                    });
                } else {
                    if (sourceValue == 'F') {
                        object['from_product'] = e['id'];
                        object['from_product_code'] = e['product_code'];
                        object['from_product_name'] = e['product_name'];
                        object['from_product_unit_name'] = e['product_unit_name'];
                        object['from_quantity'] = 1;
                        destCode.current?.focus();
                    } else if (sourceValue == "T") {
                        object['to_product'] = e['id'];
                        object['to_product_code'] = e['product_code'];
                        object['to_product_name'] = e['product_name'];
                        object['to_product_unit_name'] = e['product_unit_name'];
                        object['to_quantity'] = 0;
                        destQty.current?.focus();
                    }
                }
            }

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setFlag('');
    }

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        }

        setIsOpen(false);
    }

    const onOpenSelect = (table: string, flag: string) => {
        setFlag(flag);
        setTableSelect(table);
        setIsOpen(true);
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }
   
    useEffect(() => {
        init();
    }, []);
    
    useEffect(() => {
        if (prop.onSaved) {
            let object = JSON.parse(JSON.stringify(prop.configSeparate));
            object['is_continue'] = 1
            setData(object);
            setInputData(object);
        }
    }, [prop.onSaved]);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='productFrom' style={{marginTop: '20px'}}>
                    <label className='title'>สินค้าต้นทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า <span>*</span></label>
                                <Input name="from_product_code" value={fromProductCode} placeholder="รหัสสินค้า" onChange={onDataChange} onKeyUp={(e) => { onSearchInputChange(e, 'F') }} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('product', 'F')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="from_product_name" value={fromProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน</label>
                                <Input name="from_quantity" value={fromQuantity} placeholder="จำนวน" disabled />
                                <span>{fromProductUnitName}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="mouse-scroll">
                    <div>
                        <span className="down-arrow-1"></span>
                        <span className="down-arrow-2"></span>
                        <span className="down-arrow-3"></span>
                    </div>
                </div>

                <div className='productTo' style={{marginTop: '20px'}}>
                    <label className='title'>สินค้าปลายทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า <span>*</span></label>
                                <Input ref={destCode} name="to_product_code" value={toProductCode} placeholder="รหัสสินค้า" onChange={onDataChange} onKeyUp={(e) => { onSearchInputChange(e, 'T') }} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('product', 'T')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="to_product_name" value={toProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน <span>*</span></label>
                                <Input ref={destQty} name="to_quantity" value={toQuantity} placeholder="จำนวน" onChange={onDataChange} />
                                <span>{toProductUnitName}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    prop.mode == 'ADD' ? <Checkbox name='is_continue' style={{marginTop: '20px'}} checked={isContinue} onChange={onDataChange}>ทำรายการต่อเนื่อง</Checkbox> : null
                }
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default ConfigSeparateDetail;
