import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import { isEmpty } from "./utils/util";
import { increase, initailData, userSelector } from "./store/user/slice";
import { loadingSelector, activeLoading } from "./store/loading/slice";
import { useAppDispatch } from "./store/index";
import { decode as base64_decode } from 'js-base64';

import NavMenu from './components/nav';
import Sale from './components/sale';
import Product from './components/product/product';
import CheckStock from "./components/check_stock/check-stock";
import GoodReceive from "./components/good_receive/good-receive";
import Separate from "./components/separate/separate";
import Include from "./components/include/include";
import Withdraw from "./components/withdraw/withdraw";
import ConfigGeneral from "./components/config/general/index";
import ConfigSeparate from "./components/config/separate/config-separate";
import Employee from "./components/employee/employee";
import Login from "./components/login";
import Vendor from "./components/vendor/vendor";
import Member from "./components/member/member";
import MemberGroup from "./components/member_group/member-group";

import './App.css';
import './assets/css/login.css';
import './assets/css/nav.css';
import './assets/css/sale.css';
import './assets/css/config.css';
import './assets/css/table.list.css';
import './assets/css/antd.overwrite.css';
import './assets/css/react.datatime.overwrite.css';
import './assets/css/arrow.down.css';

function App() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userReducer = useSelector(userSelector);
    const loadingReducer = useSelector(loadingSelector);
    
    useEffect(() => {
        let uuid = isEmpty(window.localStorage.getItem("uuid")) ? "" : window.localStorage.getItem("uuid") + "";
        dispatch(activeLoading({active: true}));

        if (window.location.pathname != "/login") {
            if (!isEmpty(uuid)) {
              let data = JSON.parse(base64_decode(uuid));
              dispatch(increase(data));
    
              if (isEmpty(data.id) || data.id <= 0) {
                window.localStorage.removeItem("p");
                dispatch(initailData());
                navigate(`/login`);
              }
            } else {
              if (isEmpty(userReducer.id) || userReducer.id <= 0) {
                window.localStorage.removeItem("p");
                dispatch(initailData());
                navigate(`/login`);
              }
            }
        } else {
          if (!isEmpty(uuid)) {
            let data = JSON.parse(base64_decode(uuid));
            dispatch(increase(data));
    
            if (!isEmpty(data.id) && data.id > 0) {
                navigate(`/${data["default_page"]}`)
            }
          } 
        //   else {
        //     if (!isEmpty(userReducer.id) && userReducer.id > 0) {
        //       navigate(`/dashboard`);
        //     }  
        //   }
        }
        dispatch(activeLoading({active: false}));
    }, []);


    return (
        <div className='App'>
            { loadingReducer.active ? <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> : null }
            { window.location.pathname !== "/login" ?
                <div className='nav-container'>
                    <NavMenu></NavMenu>
                </div> : null 
            }
            { window.location.pathname !== "/login" ?
                <div className='content-container'>
                    <Routes>
                        <Route path="/sale" element={<Sale />} />
                        <Route path="/product" element={<Product />} />
                        <Route path="/check/stock" element={<CheckStock />} />
                        <Route path="/good/receive" element={<GoodReceive />} />
                        <Route path="/separate" element={<Separate />} />
                        <Route path="/include" element={<Include />} />
                        <Route path="/withdraw" element={<Withdraw />} />

                        <Route path="/member" element={<Member />} />
                        <Route path="/member/group" element={<MemberGroup />} />
                        
                        <Route path="/config/general" element={<ConfigGeneral />} />
                        <Route path="/config/separate" element={<ConfigSeparate />} />

                        <Route path="/employee" element={<Employee />} />
                        <Route path="/vendor" element={<Vendor />} />
                    </Routes>
                </div>
                : 
                <Routes>
                    <Route path="/login" element={<Login />} />
                </Routes> 
            }
        </div>
    );
}

export default App;
