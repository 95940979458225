import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty, exportExcel } from '../../utils/util';
import { Table, Input, Button, Select } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { api_product_type } from '../../api/product';
import { productType } from '../../model/master';
import { filterOption } from '../../utils/select';

interface props {
    page: string, 
    placeholder: string, 
    columns: any, 
    data: any, 
    filterField: any,
    onAdd: () => void,
    onAddMulti?: () => void
}

const { Option } = Select;
const CommonTableList: React.FC<props> = (prop) => {

    const navigate = useNavigate();
    const [uri, setUri] = useState("");
    const [search, setSearch] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [filterField, setFilterField] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [productType, setProductType] = useState(0);
    const [productTypeList, setProductTypeList] = useState<productType[]>([]);

    const init = async () => {
        if (prop.columns.length > 0) {
            setColumns(prop.columns);
        }

        if (prop.data.length > 0) {
            setData(prop.data);
        } else {
            setData([]);
        }

        if (prop.filterField.length > 0) {
            setFilterField(prop.filterField);
        }

        setPlaceholder(prop.placeholder);
        setUri(prop.page)

        if (prop.page == "product") {
            let getProductType = await api_product_type('LIST', {});
            setProductTypeList(getProductType);  
        }
    }

    const onSearch = (event: any) => {
        setSearch(event.target.value);
        onFilter();
    }

    const onFilter = () => {
        let filterData = data;
        let condition = "";

        filterField.forEach(key => {
            condition += `((String(x['${key}'])).toUpperCase().indexOf(search.toUpperCase()) > -1) || `;
        });

        if (condition !== '') {
            const lastIndex = condition.lastIndexOf('||');
            filterData = filterData.filter((x) => { 
                return eval(condition.slice(0, lastIndex));
            });
        }

        if (prop.page == "product") {
            if (!isEmpty(productType) && productType > 0) {
                filterData = filterData.filter((x: any) => { 
                    return x['product_type'] == productType;
                });
            }
        }

        return isEmpty(search) && (isEmpty(productType) || productType <= 0) ? data : filterData;
    }

    const onPrint = () => {
        console.log('Print !!')
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onExport = () => {
        let data = onFilter();
        if (selectedRowKeys.length > 0) {
            data = data.filter((x) => { return selectedRowKeys.includes(x['key']) });
        }
        exportExcel(uri, data);
    }

    useEffect(() => {
        init();
    }, [prop.data]);

    return (
        <>
            <div className='filterBoxList'>
                <div className='filterBox' style={{width: '100%', padding: '0', marginBottom: '0', boxShadow: 'none'}}>
                    <div className='searchBox'>
                        <label>ค้นหา</label><QuestionCircleOutlined rev={undefined} />
                        <Input 
                            className='uppercase'
                            placeholder={`${placeholder}`}
                            value={search}
                            onChange={onSearch}
                        />
                    </div>
                    <div className='actionBox'>
                        <Button className='btn-common' type="primary" onClick={() => { onExport() }}>
                            <FontAwesomeIcon icon="file-excel" /> Excal
                        </Button>
                        <Button className='btn-common' type="primary" onClick={prop.onAdd}>
                            <FontAwesomeIcon icon="plus" /> เพิ่ม
                        </Button>
                        <Button className='btn-common' type="primary" onClick={prop.onAddMulti}>
                            <FontAwesomeIcon icon="plus" /> เพิ่มหลายรายการ
                        </Button>
                        <Button className='btn-common' type="primary" onClick={onPrint}>
                            <FontAwesomeIcon icon="print" /> พิมพ์
                        </Button>
                    </div>
                </div>
                { prop.page == "product" ?
                    <div className='filterBox' style={{width: '100%', padding: '0', margin: '20px 0 0 0', boxShadow: 'none'}}>
                        <div className='searchBox'>
                            <label style={{marginRight: '10px'}}>ประเภทสินค้า</label>
                            <Select value={productType} showSearch filterOption={filterOption} onChange={(e) => { setProductType(e); onFilter(); }} style={{width: '30%'}} >
                                <Option key={0} value={0} code={""}>เลือกประเภท</Option>
                                {
                                    productTypeList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                : null }
            </div>
            <Table columns={columns} dataSource={onFilter()} rowSelection={rowSelection} scroll={{ x: 1366, y: window.innerHeight - 322 }} />
        </>
    );
}

export default CommonTableList;