import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { include } from '../../model/include';
import { api_product } from '../../api/product';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonSearch from '../common/common-search';
import { api_config_separate } from '../../api/config';
import { isEmpty } from '../../utils/util';

interface props {
    includeData: include,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const IncludeDetail: React.FC<props> = (prop) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tableSelect, setTableSelect] = useState('');
    const [flag, setFlag] = useState('');

    const [data, setData] = useState({});
    const [productList, setProductList] = useState([]);

    const [fromProductCode, setFromProductCode] = useState('');
    const [fromProductName, setFromProductName] = useState('');
    const [fromQuantity, setFromQuantity] = useState(0);
    const [fromProductUnitName, setFromProductUnitName] = useState('');

    const [toProductCode, setToProductCode] = useState('');
    const [toProductName, setToProductName] = useState('');
    const [toQuantity, setToQuantity] = useState(0);
    const [configToQuantity, setConfigToQuantity] = useState(0);
    const [toProductUnitName, setToProductUnitName] = useState('');
    const [remark, setRemark] = useState('');

    const init = async () => {
        setData(prop.includeData);
        setInputData(prop.includeData);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setFromProductCode(object.from_product_code != null ? object.from_product_code : '');
        setFromProductName(object.from_product_name != null ? object.from_product_name : '');
        setFromQuantity(object.from_quantity != null ? object.from_quantity : '');
        setFromProductUnitName(object.from_product_unit_name != null ? object.from_product_unit_name : '');
        setToProductCode(object.to_product_code != null ? object.to_product_code : '');
        setToProductName(object.to_product_name != null ? object.to_product_name : '');
        setToQuantity(object.to_quantity != null ? object.to_quantity : '');
        setConfigToQuantity(object.config_to_quantity != null ? object.config_to_quantity : '');
        setToProductUnitName(object.to_product_unit_name != null ? object.to_product_unit_name : '');
        setRemark(object.remark != null ? object.remark : '');
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        if (key == 'to_quantity') {
            object['from_quantity'] = calculateToProduct(value);
        }
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const calculateToProduct = (value: number) => {
        return ((isEmpty(value) ? 0 : value) * configToQuantity)
    }

    const onSearchInputChange = (e: any, flag: string) => {
        if (e.keyCode == 13) {
            let getProduct = productList.filter(x => { return x['product_code'] == e.target.value });
            if (getProduct.length > 0) {
                setFlag(flag);
                onManageProduct('A', getProduct[0], flag);
            } else {
                Modal.warning({
                    ...getConfigModal('แจ้งเตือน', 'ไม่พบสินค้าที่ค้นหา')
                });

                if (flag == 'F') {
                    setFromProductCode('');
                } else {
                    setToProductCode('');
                }
            }
        }
    }

    const onManageProduct = async (key: string, e: any, sourceFlag?: string) => {
        let object: any = JSON.parse(JSON.stringify(data));
        let sourceValue: string | undefined = flag;
        if (!isEmpty(sourceFlag)) {
            sourceValue = sourceFlag
        }

        if (key == 'A') {
            if (sourceValue == 'F') {
                object['to_product'] = e['id'];
                object['to_product_code'] = e['product_code'];
                object['to_product_name'] = e['product_name'];
                object['to_product_unit_name'] = e['product_unit_name'];
                object['to_quantity'] = '1';

                let getSeparate = await api_config_separate('GET_BY_VALUE', {}, e['id']);
                if (isEmpty(getSeparate['to_product']) || getSeparate['to_product'] <= 0) {
                    object = initInclude();
                    Modal.warning({
                        ...getConfigModal('แจ้งเตือน', 'สินค้านี้ไม่มีการตั้งค่า')
                    });
                } else {
                    object['from_product'] = getSeparate['to_product'];
                    object['from_product_code'] = getSeparate['to_product_code'];
                    object['from_product_name'] = getSeparate['to_product_name'];
                    object['from_product_unit_name'] = getSeparate['to_product_unit_name'];
                    object['config_to_quantity'] = getSeparate['config_to_quantity'];
                    object['from_quantity'] = getSeparate['to_quantity'];
                }
            }

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setFlag('');
    }

    const initInclude = () => {
        let object: any = {};
        object['from_product'] = 0;
        object['from_product_code'] = '';
        object['from_product_name'] = '';
        object['from_product_unit_name'] = '';
        object['from_product_cost'] = 0;
        object['from_quantity'] = 0;
        
        object['to_product'] = 0;
        object['to_product_code'] = '';
        object['to_product_name'] = '';
        object['to_product_unit_name'] = '';
        object['to_product_cost'] = 0;
        object['to_product_price'] = 0;
        object['config_to_quantity'] = 0;
        object['to_quantity'] = 0;

        return object;
    } 

    const handleSelected = (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        }

        setIsOpen(false);
    }

    const onOpenSelect = (table: string, flag: string) => {
        setFlag(flag);
        setTableSelect(table);
        setIsOpen(true);
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); }}>ยกเลิก</Button>
        </>
    }
   
    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='productFrom' style={{marginTop: '20px'}}>
                    <label className='title'>สินค้าต้นทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า</label>
                                <Input name="from_product_code" value={fromProductCode} placeholder="รหัสสินค้า" disabled />
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="from_product_name" value={fromProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน</label>
                                <Input name="from_quantity" value={fromQuantity} placeholder="จำนวน" disabled />
                                <span>{fromProductUnitName}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="mouse-scroll">
                    <div>
                        <span className="down-arrow-1"></span>
                        <span className="down-arrow-2"></span>
                        <span className="down-arrow-3"></span>
                    </div>
                </div> */}
                {/* <span style={{fontSize: '100px'}}>&#8593;</span> */}

                <div className='productTo' style={{marginTop: '10%'}}>
                    <label className='title'>สินค้าปลายทาง</label>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ค้นหาสินค้า<span> *</span></label>
                                <Input name="to_product_code" value={toProductCode} placeholder="รหัสสินค้า" onChange={onDataChange} onKeyUp={(e) => { onSearchInputChange(e, 'F') }} />
                                <Button className='btn-common' onClick={() => {onOpenSelect('product', 'F')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>ชื่อสินค้า</label>
                                <Input name="to_product_name" value={toProductName} placeholder="ชื่อสินค้า" disabled />
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวน <span>*</span></label>
                                <Input name="to_quantity" value={toQuantity} placeholder="จำนวน" onChange={onDataChange} />
                                <span>{toProductUnitName}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='formInput' style={{justifyContent: "flex-end", marginTop: "20px"}}>
                    <label>หมายเหตุ</label>
                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onDataChange} style={{marginLeft: "10px"}} />
                </div>

            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    );
}

export default IncludeDetail;
