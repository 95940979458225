import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, InputRef, Modal } from 'antd';
import { goodReceive } from '../../model/good-receive';
import { initProduct, product } from '../../model/master';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonDataTable from '../common/common-data-table';
import { api_good_receive, api_product } from '../../api/product';
import CommonSearch from '../common/common-search';
import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { isEmpty } from '../../utils/util';
import CommonDetail from '../common/common-detail';
import GoodReceiveProduct from './good-receive-product';
import ProductDetail from '../product/product-detail';

interface props {
    goodReceive: goodReceive,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const GoodReceiveDetail: React.FC<props> = (prop) => {

    const productCodeRef = useRef<InputRef>(null);

    const [open, setOpen] = useState(false);
    const [openAddProduct, setOpenAddProduct] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [tableSelect, setTableSelect] = useState('');

    const [data, setData] = useState({});
    const [product, setProduct] = useState<any>({});
    const [productData, setProductData] = useState([]);
    const [productList, setProductList] = useState([]);
    const [addProduct, setAddProduct] = useState<product>(initProduct);

    const [productCode, setProductCode] = useState('');
    const [productQty, setProductQty] = useState(1);
    const [vendorName, setVendorName] = useState('');
    const [purchaseOrderDocNo, setPurchaseOrderDocNo] = useState('');
    const [remark, setRemark] = useState('');

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    const [isContinue, setIsContinue] = useState(false);

    const productColumn: any = [
        { title: 'ลำดับ', dataIndex: 'rec', key: 'rec', width: '70px', fixed: 'left', edit: false },
        { title: 'รหัสสินค้า', dataIndex: 'product_code', key: 'product_code', type: 'LINK', width: '160px', fixed: 'left', edit: false },
        { title: 'ชื่อสินค้า', dataIndex: 'product_name', key: 'product_name', edit: false },
        { title: 'จำนวน', dataIndex: 'quantity', key: 'quantity', type: 'INPUT', width: '100px', edit: true },
        { title: 'น้ำหนัก', dataIndex: 'weight', key: 'weight', type: 'INPUT', width: '100px', edit: true },
        // { title: 'ต้นทุนรวม', dataIndex: 'total_cost', key: 'total_cost', type: 'CAL', width: '160px', edit: false, sumField: ['cost', 'quantity'] },
        { title: 'ต้นทุนรวม', dataIndex: 'total_cost', key: 'total_cost',  type: 'INPUT', width: '160px', edit: true },
        { title: 'ราคาต้นทุนเดิม', dataIndex: 'product_cost', key: 'product_cost', width: '160px', edit: false },
        { title: 'ราคาต้นทุน', dataIndex: 'cost', key: 'cost', type: 'CAL', width: '160px', edit: false, sumField: ['total_cost', 'quantity'], operationCal: 'div' },
        { title: 'ราคาขายเดิม', dataIndex: 'price_retail', key: 'price_retail', width: '160px', edit: false },
        { title: 'ราคาขาย', dataIndex: 'price', key: 'price', type: 'INPUT', width: '160px', edit: true },
        { title: 'Vat', dataIndex: 'vat', key: 'vat', type: 'SELECT', width: '100px', edit: true, listDefault: -1, list: [
            { id: -1, name: 'ไม่ระบุ' },
            { id: 0, name: 'Vat out' },
            { id: 1, name: 'Vat in' }
        ]}
    ]

    const init = async () => {
        setData(prop.goodReceive);

        let getProduct = await api_product('LIST', {});
        setProductList(getProduct);

        if (prop.goodReceive.id > 0) {
            setIsUpdate(true);
            setProductData([]);
            let getGoodReceive = await api_good_receive('GET', {}, prop.goodReceive.id);
            setData(getGoodReceive);
            setInputData(getGoodReceive);
            prop.onChange(getGoodReceive);
        }
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const setInputData = (object: any) => {
        setVendorName(object.vendor_name != null ? object.vendor_name : '');
        setPurchaseOrderDocNo(object.purchase_order_doc_no != null ? object.purchase_order_doc_no : '');
        setRemark(object.remark != null ? object.remark : '');
        setProductData(object.detail != null ? object.detail : []);
        setTotalQuantity(object.quantity);
        setTotalWeight(object.weight);
        setTotalCost(object.cost);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onProductChange = (e: any) => {
        setProductCode(e.target.value);
    }

    const onProductQtyChange = (e: any) => {
        setProductQty(isEmpty(e.target.value) ? 0 : parseInt(e.target.value));
    }

    const onSearchInputChange = (e: any) => {
        if (e.keyCode == 13) {
            let code = e.target.value;
            if (!isEmpty(code)) {
                if (e.target.name == 'product_qty') {
                    code = productCode;
                }

                let getProduct = productList.filter(x => { return x['product_code'] == code });
                if (getProduct.length > 0) {
                    onManageProduct('A', getProduct[0]);
                } else {
                    let item = initProduct;
                    item['id'] = -1;
                    item['product_code'] = code;
                    item['product_name'] = '-';
                    item['quantity'] = 0;
                    item['product_cost'] = "0";
                    onManageProduct('A', item);
                    
                    // setAddProduct(item);
                    // setOpenAddProduct(true);
                }
            }
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onOpenSelect = (table: string) => {
        setTableSelect(table);
        setIsOpen(true);
    }

    const handleSelected = async (e: any) => { 
        if (e.table == 'product') {
            onManageProduct('A', e);
        } else if (e.table == 'vendor') {
            onDataChange( {
                target: {
                    name: 'vendor',
                    value: e.id
                }
            })

            onDataChange( {
                target: {
                    name: 'vendor_name',
                    value: e.name
                }
            })

            let getProduct = await api_product('GET_BY_VALUE', {}, e.id);
            if (getProduct.length > 0) {
                getProduct.forEach((p: any) => {
                    onManageProduct('A', p);    
                });
            }
        }

        if (!isContinue) {
            setIsOpen(false);
        }
    }

    const cancelSelected = (key: string) => {
        if (key == 'vendor') {
            onDataChange( {
                target: {
                    name: 'vendor',
                    value: 0
                }
            })

            onDataChange( {
                target: {
                    name: 'vendor_name',
                    value: ''
                }
            })
        }
    }

    const onManageProduct = (key: string, e: any) => {
        let object: any = data;

        if (key == 'A') {
            let index = object.detail.findIndex((x: any) => {
                let status = false;
                
                if (x['product_code'] == e['product_code']) {
                    status = true;
                }

                if (e['id'] < 0) {
                    if (x['product'] == e['id'] && x['product_code'] == e['product_code']) {
                        status = true;
                    } else {
                        status = false;
                    }
                }
                return status;
            });
            if (index >= 0) {
                let product_data = object.detail[index];
                let qty = product_data['quantity'];

                if (product_data['id'] < 0 && e['id'] > 0) {
                    object.detail[index]['id'] = 0;
                    object.detail[index]['product'] = e['id'];
                    object.detail[index]['vat'] = e['vat'];
                    object.detail[index]['product_code'] = e['product_code'];
                    object.detail[index]['product_name'] = e['product_name'];
                    object.detail[index]['product_name_other'] = e['product_name_other'];
                    object.detail[index]['product_cost'] = e['product_cost'];
                    object.detail[index]['product_type'] = e['product_type'];
                    object.detail[index]['product_unit'] = e['product_unit'];
                    object.detail[index]['price_retail'] = e['price_retail'];
                    object.detail[index]['price'] = e['price_retail'];
                    object.detail[index]['price_wholesale'] = e['price_wholesale'];
                    object.detail[index]['price_retail_member'] = e['price_retail_member'];
                    object.detail[index]['price_wholesale_member'] = e['price_wholesale_member'];
                } else {
                    object.detail[index]['quantity'] = parseInt(isEmpty(qty) ? 0 : qty) + (isEmpty(productQty) || productQty <= 0 ? 1 : productQty);
                }
            } else {
                object.detail = [
                    ...object.detail,
                    ...[{
                        "key": (object.detail.length + 1),
                        "id": e['id'] < 0 ? e['id'] : 0,
                        "good_receive": 0,
                        "quantity": isEmpty(productQty) || productQty <= 0 ? 1 : productQty,
                        "weight": 0,
                        "cost": e['product_cost'],
                        "lot": '',
                        "expire_date": '',
                        "remark": '',
                        "status": "Y",
                        "product": e['id'],
                        "vat": e['vat'],
                        "product_code": e['product_code'],
                        "product_name": e['product_name'],
                        "product_name_other": e['product_name_other'],
                        "product_cost": e['product_cost'],
                        "product_type": e['product_type'],
                        "product_unit": e['product_unit'],
                        "price_retail": e['price_retail'],
                        "price": e['price_retail'],
                        "price_wholesale": e['price_wholesale'],
                        "price_retail_member": e['price_retail_member'],
                        "price_wholesale_member": e['price_wholesale_member'],
                    }]
                ];
            }

            setData(object);
            setInputData(object);
            prop.onChange(object);
        } else if (key == 'E') {
            var index = object.detail.findIndex((x: any) => {
                return x['key'] == e['key']
            });
            object.detail[index] = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        setProductQty(1);
        calculateTotal();
    }

    const onActionProduct = (e: any) => {
        let object: any = data;
        let count = 0;

        if ('mode' in e[0]) {
            let mode = e[0].mode;
            let key = e[0].key;

            if (mode == 'remove') {
                let detail: any = [];

                object.detail.forEach((x: any) => {
                    if (x.key != key) {
                        count++;
                        x['key'] = count;
                        detail.push(x);
                    }
                });
                object.detail = detail;

                setData(object);
                setInputData(object);
                prop.onChange(object);
            } else if (mode == 'edit') {
                setProduct(object.detail.filter((x: any) => { return x['key'] == key})[0]);
                setOpen(true);
            } else if (mode == 'link') {
                let item = JSON.parse(JSON.stringify(e[0].data))
                let item_product = initProduct;
                item_product['id'] = 99999999;
                item_product['product_code'] = item['product_code'];
                item_product['product_name'] = item['product_name'];
                item_product['quantity'] = 0;
                item_product['product_cost'] = item['cost'];
                setAddProduct(item_product);
                setOpenAddProduct(true);
            }
        } else {
            e.forEach((x: any) => {
                x['cost'] = parseFloat(parseFloat((parseFloat(x['total_cost'] + '') / parseFloat(x['quantity'] + '')) + '').toFixed(2));
            });
            object.detail = e;

            setData(object);
            setInputData(object);
            prop.onChange(object);
        }

        calculateTotal();
    }

    const onEditedProduct = () => {
        if (isEmpty(product['cost']) || isEmpty(product['quantity']) || product['quantity'] < 0 || isEmpty(product['weight']) || product['weight'] < 0 ||
            isEmpty(product['price_retail']) || isEmpty(product['price_wholesale']) || isEmpty(product['price_retail_member']) || isEmpty(product['price_wholesale_member'])) {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        } else {
            onManageProduct('E', product);
            setOpen(false);
        }
    }

    const setFooter = () => {
        return <>
            <br />
            <Checkbox name='is_continue' style={{marginRight: '20px'}} checked={isContinue} onChange={(e: any) => {setIsContinue(e.target.checked)}}>ทำรายการต่อเนื่อง</Checkbox>
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); setIsContinue(false); }}>ยกเลิก</Button>
        </>
    }

    const calculateTotal = () => {
        let quantity: number = 0; 
        let weight: number = 0;
        let cost: number = 0;
        let object: any = data;

        if (object.detail.length > 0) {
            object.detail.forEach((object: any) => {
                quantity += parseInt(object['quantity']);
                weight += parseInt(object['weight']);
                cost += parseFloat(((parseInt(object['quantity']) < 1 ? 1 : parseInt(object['quantity'])) * parseFloat(object['cost'])).toFixed(2));
            });
        }

        object['quantity'] = quantity;
        object['weight'] = weight;
        object['cost'] = cost

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onAddProductClosed = () => {
        setProductCode('');
        setProductQty(1);
        productCodeRef.current?.focus();
        setOpenAddProduct(false);
    }

    const onSaveProductSuccess = async (code: string) => {
        setAddProduct(initProduct); 
        setOpenAddProduct(false);
        let getProductList = await api_product('LIST', {});
        setProductList(getProductList);
        let getProduct = getProductList.filter((x: any) => { return x['product_code'] == code });
        if (getProduct.length > 0) {
            onManageProduct('A', getProduct[0]);
            setProductCode('');
            setProductQty(1);
            productCodeRef.current?.focus();
        }
    }

    const onSaveProduct = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(addProduct));

        // if (!isEmpty(body['product_code']) && body['product_type'] > 0 && body['product_unit'] > 0 && !isEmpty(body['product_cost']) && !isEmpty(body['price_retail'])) {
        //     body['product_cost'] = isEmpty(body['product_cost']) ? null : body['product_cost']
        //     body['price_retail'] = isEmpty(body['price_retail']) ? null : body['price_retail']
        //     body['price_wholesale'] = isEmpty(body['price_wholesale']) ? null : body['price_wholesale']
        //     body['price_retail_member'] = isEmpty(body['price_retail_member']) ? null : body['price_retail_member']
        //     body['price_wholesale_member'] = isEmpty(body['price_wholesale_member']) ? null : body['price_wholesale_member']
        //     body['init_order_amount'] = isEmpty(body['init_order_amount']) ? null : body['init_order_amount']
        //     body['point_purchase'] = isEmpty(body['point_purchase']) ? null : body['point_purchase']
        //     body['overflow_point'] = isEmpty(body['overflow_point']) ? null : body['overflow_point']
        //     body['limit_quantity'] = isEmpty(body['limit_quantity']) ? null : body['limit_quantity']
        //     body['point'] = isEmpty(body['point']) ? null : body['point']
        //     body['vendor'] = body['vendor'].length <= 0 ? "" : JSON.stringify(body['vendor']).replace('[', '').replace(']', '') + ","
        //     body['remark'] = isEmpty(body['remark']) ? null : body['remark']
        //     body['vat'] = body['vat'] < 0 ? null : body['vat']
        //     body['tier_price'] = isEmpty(body['tier_price']) ? "[]" : body['tier_price']

        //     let res = await api_product(operation, body, key_id);
        //     if (res === 1) {
        //         setOpen(false);
        //         Modal.success({
        //             ...getConfigModal('สำเร็จ', 'เพิ่มข้อมูลสำเร็จแล้ว'),
        //             ...{ onOk() {onSaveProductSuccess(body['product_code']);}}
        //         });
        //     } else {
        //         Modal.error({
        //             ...getConfigModal('ผิดพลาด', 'เพิ่มข้อมูลไม่สำเร็จ'),
        //         });
        //     }
        // } else {
        //     Modal.warning({
        //         ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
        //     });
        // }

    }

    useEffect(() => {
        init();
    }, []);
    
    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox' style={{'marginBottom': '50px', 'maxHeight': '50%'}}>
                    <div className='searchDataBox'>
                        <label>ค้นหาสินค้า</label>
                        <Input ref={productCodeRef} name="product_code" value={productCode} placeholder="รหัสสินค้า" onChange={onProductChange} onKeyUp={onSearchInputChange} />
                        <label>จำนวน</label>
                        <Input name="product_qty" value={productQty} placeholder="" style={{width: '50px'}} onChange={onProductQtyChange} onKeyUp={onSearchInputChange} />
                        <Button className='btn-common' onClick={() => {onOpenSelect('product')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                    </div>
                    <CommonDataTable columns={productColumn} data={[...productData]} operation='OUT_TABLE' scollX={1640} onChange={onActionProduct} />
                </div>
                <div className='footerBox'>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ผู้ขายส่งสินค้า</label>
                                <div>
                                    <Input name="vendor_name" value={vendorName} placeholder="ผู้ขายส่งสินค้า" 
                                        suffix={
                                            <CloseOutlined rev={undefined} style={{color: '#000099', cursor: 'pointer', display: (vendorName != '' ? 'block' : 'none')}} onClick={() => { cancelSelected('vendor') }} />
                                        } 
                                        disabled={vendorName == ''} 
                                    />
                                    <Button className='btn-common' onClick={() => {onOpenSelect('vendor')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>เลขที่ใบสั่งซื้อ</label>
                                <div>
                                    <Input name="purchase_order_doc_no" value={purchaseOrderDocNo} placeholder="เลขที่ใบสั่งซื้อ" disabled />
                                    <Button className='btn-common' onClick={() => {onOpenSelect('purchase_order')}}><FontAwesomeIcon icon="magnifying-glass" /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>จำนวนรวม</label>
                                <div>
                                    <Input name="total_quantity" value={totalQuantity} placeholder="จำนวนรวม" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>น้ำหนักรวม</label>
                                <div>
                                    <Input name="total_weight" value={totalWeight} placeholder="น้ำหนักรวม" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='headerBox'>
                        <div>
                            <div className='formInput'>
                                <label>ต้นทุนรวม</label>
                                <div>
                                    <Input name="total_cost" value={totalCost} placeholder="จำนวนรวม" disabled />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='formInput'>
                                <label>หมายเหตุ{isUpdate ? <span> *</span> : null}</label>
                                <div>
                                    <Input name="remark" value={remark} placeholder="หมายเหตุ" onChange={onDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <CommonDetail width='40%' mode={'ADD'} title={'รายละเอียดสินค้า'} open={open} onClosed={() => { setOpen(false) }} onSaved={onEditedProduct} 
                element={ <GoodReceiveProduct product={{...product}} onChange={setProduct} /> } 
            /> */}

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>

            <CommonDetail 
                width='40%' 
                mode={'ADD'} 
                title={'เพิ่มสินค้า'} 
                open={openAddProduct} 
                onClosed={onAddProductClosed} 
                onSaved={onSaveProduct} 
                element={ <ProductDetail product={{...addProduct}} onChange={(payload: any) => {setAddProduct(payload)}} /> } 
            />
        </>
    );
}

export default GoodReceiveDetail;