import React, { useEffect, useRef, useState } from 'react';
import { api_shortcut } from '../../../api/sale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'antd';
import { isEmpty } from '../../../utils/util';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { api_product } from '../../../api/product';
import CommonSearch from '../../common/common-search';

const ConfigShortcut: React.FC = (prop) => {

    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [tableSelect, setTableSelect] = useState('');
    const [shortcutId, setShortcutId] = useState(0);

    const init = async () => {
        let getShortcut = await api_shortcut('LIST', {});
        setData(getShortcut);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onDelete = async (object: any) => {
        let res = await api_shortcut('UPDATE', {'product': null}, object.id);
        alertModal(res);
    }

    const alertModal = (res: number) => {
        if (res === 1) {
            Modal.success({
                ...getConfigModal('สำเร็จ', 'จัดการมูลสำเร็จแล้ว'),
                ...{ onOk() { init(); }}
            });
        } else {
            Modal.error({
                ...getConfigModal('ผิดพลาด', 'จัดการมูลไม่สำเร็จ'),
            });
        }
    }

    const onOpenSelect = (table: string, object: any) => {
        setShortcutId(object.id);
        setTableSelect(table);
        setIsOpen(true);
    }

    const setFooter = () => {
        return <>
            <br />
            <Button className='btn-cancel' onClick={() => { setIsOpen(false); setTableSelect(''); setShortcutId(0); }}>ยกเลิก</Button>
        </>
    }

    const handleSelected = async (e: any) => { 
        if (e.table == 'product') {
            let res = await api_shortcut('UPDATE', {'product': e.id}, shortcutId);
            alertModal(res);
        }

        setIsOpen(false);
        setShortcutId(0);
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <div className='configShortcutContainer'>
                {
                    data.map((obj: any) => {
                        return <div className='configShortcutContainerDetail' key={obj.key}>
                            {
                                isEmpty(obj.product) ? <span>
                                    <Button className='btn-common icon-config' onClick={() => { onOpenSelect('product', obj) }}><FontAwesomeIcon icon="plus" /></Button> 
                                </span> :
                                <span>
                                    <Button className='btn-common icon-config' onClick={() => { onOpenSelect('product', obj) }}><FontAwesomeIcon icon="pen-to-square" /></Button> 
                                    <Button className='btn-common icon-config' onClick={() => { onDelete(obj) }}><FontAwesomeIcon icon="xmark" /></Button> 
                                </span>
                            }
                            <label>{obj.product_code}</label>
                            <label>{obj.product_name}</label>
                        </div>
                    })
                }
            </div>

            <Modal
                closeIcon={false}
                centered
                open={isOpen}
                footer={setFooter()}
                width={'50%'}
                destroyOnClose={true}
            >
                <CommonSearch table={tableSelect} onSelect={handleSelected} />
            </Modal>
        </>
    )
}

export default ConfigShortcut;