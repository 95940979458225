import React, { useEffect, useState } from 'react';
import { member, memberGroup } from '../../model/member';
import { Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { api_member_group } from '../../api/member';
import { filterOption } from '../../utils/select';
import { getDateFromDatePicker } from '../../utils/datepicker';

interface props {
    member: member,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const { Option } = Select;

const MemberDetail: React.FC<props> = (prop) => {

    const [data, setData] = useState<any>({});

    const [code, setCode] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [taxId, setTaxId] = useState('');
    const [address, setAddress] = useState('');
    const [remark, setRemark] = useState('');
    const [memberGroup, setMemberGroup] = useState(0);

    const [memberGroupList, setMemberGroupList] = useState<memberGroup[]>([]);

    const init = async () => {
        let getMemberGroup = await api_member_group('LIST', {})
        setMemberGroupList(getMemberGroup)
    }

    const setInputData = (object: any) => {
        setCode(object.code);
        setFirstname(object.firstname);
        setLastname(object.lastname);
        setTel(object.tel);
        setEmail(object.email);
        setTaxId(object.tax_id);
        setAddress(object.address);
        setRemark(object.remark);
        setMemberGroup(object.member_group)
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? e.target.value
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onMappingDataBeforeChange = (e: any, name: string) => {
        let object = {
            target: {
                name: name,
                value: typeof(e) == 'number' ? e : getDateFromDatePicker(e)
            }
        }
        onDataChange(object);
    }
    
    useEffect(() => {
        init();
        setData(prop.member);
        setInputData(prop.member);
    }, []);


    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox'>
                    <div className='InputBox'>
                        <div className='formInput'>
                            <label>รหัสสมาชิก</label>
                            <Input name="code" value={code} placeholder="M0000X" onChange={onDataChange} disabled />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อ<span> *</span></label>
                            <Input name="firstname" value={firstname} placeholder={'กรุณาป้อนชื่อ'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>นามสกุล</label>
                            <Input name="lastname" value={lastname} placeholder={'กรุณาป้อนชื่อ'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>เบอร์โทรศัพท์<span> *</span></label>
                            <Input name="tel" value={tel} placeholder={'กรุณาป้อนเบอร์โทรศัพท์'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>อีเมล</label>
                            <Input name="email" value={email} placeholder={'กรุณาป้อนอีเมล'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>เลขประจำตัวผู้เสียภาษี</label>
                            <Input name="tax_id" value={taxId} placeholder={'กรุณาป้อนเลขประจำตัวผู้เสียภาษี'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ที่อยู่</label>
                            <TextArea
                                name="address"
                                value={address}
                                onChange={onDataChange}
                                placeholder="กรุณาป้อนที่อยู่"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </div>
                        <div className='formInput'>
                            <label>กลุ่มสมาชิก</label>
                            <Select value={memberGroup} showSearch filterOption={filterOption} onChange={(e) => { onMappingDataBeforeChange(e, 'member_group') }} >
                                <Option key={0} value={0} code={""}>-</Option>
                                {
                                    memberGroupList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>หมายเหตุ</label>
                            <Input name="remark" value={remark} placeholder={'กรุณาป้อนหมายเหตุ'} onChange={onDataChange} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MemberDetail;