import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Radio, Select, Space } from 'antd';
import { daysTH, monthsTH } from '../../utils/util';
import { filterOption, filterOptionSort } from '../../utils/select';
import { getDateFromDatePicker } from '../../utils/datepicker';
import DatePicker, { DateObject } from "react-multi-date-picker";
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { product, productType, unitType, vat, vendor } from '../../model/master';
import CommonDetail from '../common/common-detail';
import CommonDataTable from '../common/common-data-table';
import { api_product_type, api_product_unit } from '../../api/product';
import { api_vendor } from '../../api/vendor';

interface props {
    product: product,
    onChange: React.Dispatch<React.SetStateAction<object>>
}

const { Option } = Select;

const ProductDetail: React.FC<props> = (prop) => {

    const months = monthsTH();
    const days = daysTH();
    const [data, setData] = useState<any>({});
    const [openTierPrice, setOpenTierPrice] = useState(false);

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [nameOther, setNameOther] = useState('');
    const [type, setType] = useState(0);
    const [unit, setUnit] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [normalType, setNormalType] = useState(1);
    const [cost, setCost] = useState('');
    const [priceRetail, setPriceRetail] = useState('');
    const [priceWholesale, setPriceWholesale] = useState('');
    const [priceRetailMember, setPriceRetailMember] = useState('');
    const [priceWholesaleMember, setPriceWholesaleMember] = useState('');
    const [initOrderAmount, setInitOrderAmount] = useState('');
    const [pointPurchase, setPointPurchase] = useState('');
    const [overflowPoint, setOverflowPoint] = useState('');
    const [limitQuantity, setLimitQuantity] = useState('');
    const [vat, setVat] = useState(-1);
    const [point, setPoint] = useState('');
    const [isCutStock, setIsCutStock] = useState(false);
    const [isTierPrice, setIsTierPrice] = useState(false);
    const [isAvgCost, setIsAvgCost] = useState(false);
    const [expireDate, setExpireDate] = useState<DateObject>(new DateObject());
    const [vendor, setVendor] = useState<any>([]);
    const [remark, setRemark] = useState('');

    const [typeList, setTypeList] = useState<productType[]>([]);
    const [unitList, setUnitList] = useState<unitType[]>([]);
    const [vatList, setVatList] = useState<vat[]>([]);
    const [vendorList, setVendorList] = useState<vendor[]>([]);

    const [tierPriceData, setTierPriceData] = useState([]);

    const init = async () => {
        setVatList([
            { key: 0, id: -1, name: '-' },
            { key: 1, id: 0, name: 'Vat out' },
            { key: 2, id: 1, name: 'Vat in' }
        ])

        let getProductType = await api_product_type('LIST', {});
        setTypeList(getProductType);

        let getProductUnit = await api_product_unit('LIST', {});
        setUnitList(getProductUnit);

        let getVendor = await api_vendor('LIST', {});
        setVendorList(getVendor);
    }

    const setInputData = (object: any) => {
        setCode(object.product_code);
        setName(object.product_name);
        setNameOther(object.product_name_other);
        setType(object.product_type);
        setUnit(object.product_unit);
        setQuantity(object.quantity)
        setNormalType(object.product_normal_type);
        setCost(object.product_cost);
        setPriceRetail(object.price_retail);
        setPriceWholesale(object.price_wholesale);
        setPriceRetailMember(object.price_retail_member);
        setPriceWholesaleMember(object.price_wholesale_member);
        setInitOrderAmount(object.init_order_amount);
        setPointPurchase(object.point_purchase);
        setOverflowPoint(object.overflow_point);
        setLimitQuantity(object.limit_quantity);
        setVat(object.vat);
        setPoint(object.point);
        setIsCutStock(object.is_cut_stock);
        setIsTierPrice(object.is_tier_price);
        setIsAvgCost(object.is_avg_cost);
        setExpireDate(new DateObject(object.expire_date));
        setVendor(object.vendor);
        setRemark(object.remark);
        setTierPriceData(object.tier_price != null ? JSON.parse(object.tier_price) : []);
    }

    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }
    
    const onTierPriceDataChange = (e: any) => {
        let object: any = {...data};
        object['tier_price'] = JSON.stringify(e);

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const onMappingDataBeforeChange = (e: any, name: string) => {
        let object = {
            target: {
                name: name,
                value: typeof(e) == 'number' ? e : getDateFromDatePicker(e)
            }
        }
        onDataChange(object);
    }

    const onOpenTierPrice = () => {
        setOpenTierPrice(true);
    }

    const onCloseTierPrice = () => {
        setOpenTierPrice(false);
    };

    const onSaveTierPrice = () => {
        setOpenTierPrice(false);
    }

    const onVendorChange = (value: string[]) => {
        let object: any = data;
        object['vendor'] = value;

        setData(object);
        setInputData(object);
        prop.onChange(object);
    }

    const tierPriceColumn: any = [
        { title: 'ขั้นต่ำ', dataIndex: 'min', key: 'min', type: 'INPUT', width: '100px', edit: true },
        { title: 'สูงสุด', dataIndex: 'max', key: 'max', type: 'INPUT', width: '100px', edit: true },
        { title: 'ราคา', dataIndex: 'price', key: 'price', type: 'INPUT', width: '100px', edit: true },
        { title: 'ประเภท', dataIndex: 'price_type', key: 'price_type', type: 'SELECT', width: '100px', edit: true, list: [{ "id": 1, "name": "ประเภท 1" }, { "id": 2, "name": "ประเภท 2" }] },
        { title: 'กลุ่ม', dataIndex: 'member_group', key: 'member_group', type: 'SELECT', width: '100px', edit: true, list: [] }
    ]

    useEffect(() => {
        init();
        setData(prop.product);
        setInputData(prop.product);
    }, []);


    return (
        <>
            <div className='detailContainer'>
                <div className='detailBox'>
                    <div className='InputBox'>
                        <div className='formInput'>
                            <label></label>
                            <div className='formInputRadio'>
                                <Radio.Group name="product_normal_type" value={normalType} onChange={onDataChange}>
                                    <Radio value={1}>สินค้าทั่วไป</Radio>
                                    <Radio value={0}>สินค้าชั่งน้ำหนัก</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        <div className='formInput'>
                            <label>รหัสสินค้า<span> *</span></label>
                            <Input name="product_code" value={code} placeholder="EM000X" onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อสินค้า<span> *</span></label>
                            <Input name="product_name" value={name} placeholder={'กรุณาป้อนชื่อสินค้า'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ชื่อสินค้าอื่นๆ</label>
                            <Input name="product_name_other" value={nameOther} placeholder={'กรุณาป้อนชื่อสินค้า'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ประเภท<span> *</span></label>
                            <Select value={type} showSearch filterOption={filterOption} onChange={(e) => { onMappingDataBeforeChange(e, 'product_type') }} >
                                <Option key={0} value={0} code={""} disabled>กรุณาเลือกประเภท</Option>
                                {
                                    typeList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>หน่วย<span> *</span></label>
                            <Select value={unit} showSearch filterOption={filterOption} onChange={(e) => { onMappingDataBeforeChange(e, 'product_unit') }} >
                                <Option key={0} value={0} code={""} disabled>กรุณาเลือกหน่วย</Option>
                                {
                                    unitList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>จำนวนสินค้า</label>
                            <Input name="quantity" value={quantity} placeholder={'กรุณาป้อนจำนวนสินค้า'} onChange={onDataChange} disabled={data.id > 0} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาต้นทุน<span> *</span></label>
                            <Input name="product_cost" value={cost} placeholder={'กรุณาป้อนราคาต้นทุน'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายปลีก<span> *</span></label>
                            <Input name="price_retail" value={priceRetail} placeholder={'กรุณาป้อนราคาขายปลีก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายส่ง</label>
                            <Input name="price_wholesale" value={priceWholesale} placeholder={'กรุณาป้อนราคาขายส่ง'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายปลีกสมาชิก</label>
                            <Input name="price_retail_member" value={priceRetailMember} placeholder={'กรุณาป้อนราคาขายปลีกสมาชิก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>ราคาขายส่งสมาชิก</label>
                            <Input name="price_wholesale_member" value={priceWholesaleMember} placeholder={'กรุณาป้อนราคาขายส่งสมาชิก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>จำนวนสั่งซื้อเริ่มต้น</label>
                            <Input name="init_order_amount" value={initOrderAmount} placeholder={'กรุณาป้อนจำนวนสั่งซื้อเริ่มต้น'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>จุดสั่งซื้อ</label>
                            <Input name="point_purchase" value={pointPurchase} placeholder={'กรุณาป้อนจุดสั่งซื้อ'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>จุดล้นสต๊อก</label>
                            <Input name="overflow_point" value={overflowPoint} placeholder={'กรุณาป้อนจุดล้นสต๊อก'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>จำกัดจำนวน</label>
                            <Input name="limit_quantity" value={limitQuantity} placeholder={'กรุณาป้อนจำกัดจำนวน'} onChange={onDataChange} />
                        </div>

                        <div className='formInput'>
                            <label>Vat</label>
                            <Select value={vat} showSearch filterOption={filterOption} onChange={(e) => { onMappingDataBeforeChange(e, 'vat') }} >
                                {
                                    vatList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>แต้มสินค้า</label>
                            <Input name="point" value={point} placeholder={'กรุณาป้อนแต้มสินค้า'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label>วันหมดอายุ</label>
                            <div className='formInputDate'>
                                <DatePicker
                                    className="fromInputDatetime"
                                    months={months}
                                    weekDays={days}
                                    value={expireDate}
                                    onChange={(e) => { onMappingDataBeforeChange(e, 'expire_date') }}
                                    render={<InputIcon/>}
                                    format={"DD/MM/YYYY"}
                                />
                            </div>
                        </div>
                        <div className='formInput'>
                            <label>ผู้ขาย</label>
                            <Select 
                                mode="multiple"
                                allowClear
                                value={vendor} showSearch filterOption={filterOption} filterSort={filterOptionSort} onChange={onVendorChange} >
                                {/* value={vendor} showSearch filterOption={filterOption} filterSort={filterOptionSort} onChange={(e) => { onMappingDataBeforeChange(e, 'vendor') }} > */}
                                {/* <Option key={0} value={0} code={""} disabled>กรุณาเลือกผู้ขาย</Option> */}
                                {
                                    vendorList.map(obj => {
                                        return <Option key={obj.id} value={obj.id}>{obj.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                        <div className='formInput'>
                            <label>หมายเหตุ</label>
                            <Input name="remark" value={remark} placeholder={'กรุณาป้อนหมายเหตุ'} onChange={onDataChange} />
                        </div>
                        <div className='formInput'>
                            <label></label>
                            <div className='formInputCheckbox'>
                                <Checkbox name='is_cut_stock' checked={isCutStock} onChange={onDataChange}>ไม่ตัดสต๊อก</Checkbox>
                            </div>
                        </div>
                        <div className='formInput'>
                            <label></label>
                            <div className='formInputCheckbox'>
                                <Checkbox name='is_avg_cost' checked={isAvgCost} onChange={onDataChange}>คำนวนต้นทุนแบบเฉลี่ย</Checkbox>
                            </div>
                        </div>
                        <div className='formInput'>
                            <label></label>
                            <div className='formInputCheckbox'>
                                <Checkbox name='is_tier_price' checked={isTierPrice} onChange={onDataChange}>ราคาหลายระดับ</Checkbox>
                                { isTierPrice ? <Button className='btn-common' onClick={onOpenTierPrice}>ตั้งค่าราคาหลายระดับ</Button> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CommonDetail width='40%' mode="VIEW_ONLY" title="ตั้งค่าราคาหลายระดับ" open={openTierPrice} onClosed={onCloseTierPrice} onSaved={onSaveTierPrice} 
                element={ <CommonDataTable columns={tierPriceColumn} data={[...tierPriceData]} operation='IN_TABLE' scollX={768} onChange={onTierPriceDataChange} />} 
            />
        </>
    );
}

export default ProductDetail;