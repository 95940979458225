import React, { useEffect, useState } from 'react';
import CommonDetail from '../common/common-detail';
import CommonTableList from '../common/common-table-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { api_check_stock } from '../../api/product';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { isEmpty } from '../../utils/util';
import { checkStock, initCheckStock } from '../../model/check-stock';
import CheckStockDetail from './check-stock-detail';

const CheckStock: React.FC = (prop) => {
    
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [checkStock, setCheckStock] = useState<checkStock>(initCheckStock);

    const filterField: any = ['document_no'];

    const placeholder_txt: string = 'เลขที่เอกสาร' 

    const columns: ColumnsType<any> = [
        { title: 'เลขที่เอกสาร', dataIndex: 'document_no', key: 'document_no', width: '200px', fixed: 'left' },
        { title: 'วันที่นับสต๊อก', dataIndex: 'create_date', key: 'create_date', width: '200px', },
        { title: 'สินค้าปกติ', dataIndex: 'quantity', key: 'quantity' },
        { title: 'สินค้าหาย', dataIndex: 'lost', key: 'weight' },
        { title: 'สินค้าเกิน', dataIndex: 'over', key: 'cost' },
        { title: 'อัพเดทสต๊อก', dataIndex: 'is_update_stock', key: 'is_update_stock', render: (_, record) => 
            <label>{record.is_update_stock == 0 ? 'ไม่อัพเดทสต๊อก' : record.is_update_stock == 1 ? 'อัพเดทสต๊อก' : ''}</label>
        },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getCheckStock = await api_check_stock('LIST', {});
        setData(getCheckStock);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
        }
    }

    const onShowDetail = (mode: string, checkStockData?: checkStock) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('บันทึกการนับสต๊อก')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขการนับสต๊อก')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดการนับสต๊อก')
        }

        setMode(mode);
        setCheckStock(checkStockData !== undefined && checkStockData !== null ? checkStockData : initCheckStock);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let body = JSON.parse(JSON.stringify(checkStock));

        if (body['detail'] && body['detail'].length > 0) {
            if (body['is_update_stock'] == 1) { 
                if (body['lost'] > 0 || body['over'] > 0) {
                    Modal.confirm({
                        ...getConfigModal('แจ้งเตือน', 'การรับเข้ามีสินค้าขาดหรือเกิน คุณยืนยันที่จะอัพเดทสต๊อกใช่หรือไม่ ?'),
                        ...{ async onOk() { onConfirmSave() }}
                    });
                }
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }
    }

    const onConfirmSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(checkStock));
        if (checkStock.id > 0) {
            operation = 'UPDATE';
            key_id = checkStock.id;
        }

        body['quantity'] = isEmpty(body['quantity']) ? 0 : body['quantity']
        body['lost'] = isEmpty(body['lost']) ? 0 : body['lost']
        body['over'] = isEmpty(body['over']) ? 0 : body['over']
        body['remark'] = isEmpty(body['remark']) ? null : body['remark']

        let res = await api_check_stock(operation, body, key_id);
        if (res === 1) {
            setOpen(false);
            Modal.success({
                ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                ...{ onOk() { init(); }}
            });
        } else {
            Modal.error({
                ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
            });
        }
    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_check_stock('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setCheckStock(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='check-stock' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='check-stock' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='70%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <CheckStockDetail checkStock={{...checkStock}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default CheckStock;