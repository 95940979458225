export interface checkStock {
    key: number,
    id: number,
    document_no: string,
    quantity: number,
    lost: number,
    over: number,
    is_update_stock: number,
    remark: string,
    create_date: string,
    detail: checkStockDetail[]
}

export interface checkStockDetail {
    key: number,
    id: number,
    quantity: number,
    countable: number,
    remark: string,
    status: number,
    product_code: string,
    product_name: string,
}

export const initCheckStock = {
    key: 0,
    id: 0,
    document_no: "",
    quantity: 0,
    lost: 0,
    over: 0,
    is_update_stock: 0,
    remark: "",
    create_date: "",
    detail: []
}

export const initCheckStockDetail = {
    key: 0,
    id: 0,
    quantity: 0,
    countable: 0,
    remark: "",
    status: 0,
    product_code: "",
    product_name: "",
}