import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { employee, initEmployee } from '../../model/master';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_employee } from '../../api/employee';
import CommonTableList from '../common/common-table-list';
import CommonDetail from '../common/common-detail';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import EmployeeDetail from './employee-detail';
import { isEmpty } from '../../utils/util';

const Employee: React.FC = (prop) => {

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [employee, setEmployee] = useState<employee>(initEmployee);

    const filterField: any = ['code', 'name', 'tel'];

    const placeholder_txt: string = 'รหัสพนักงาน / ชื่อพนักงาน / เบอร์โทรศัพท์' 

    const columns: ColumnsType<employee> = [
        { title: 'รหัสพนักงาน', dataIndex: 'code', key: 'code', fixed: 'left' },
        { title: 'ชื่อพนักงาน', dataIndex: 'name', key: 'name' },
        { title: 'เบอร์โทรศัพท์', dataIndex: 'tel', key: 'tel' },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getEmployee = await api_employee('LIST', {});
        setData(getEmployee);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onShowDetail = (mode: string, employeeData?: employee) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('เพิ่มพนักงาน')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขพนักงาน')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดพนักงาน')
        }

        setMode(mode);
        setEmployee(employeeData !== undefined && employeeData !== null ? employeeData : initEmployee);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(employee));
        if (employee.id > 0) {
            operation = 'UPDATE';
            key_id = employee.id;
        }
        let isPasswordChange = 'isPasswordChange' in body ? body['isPasswordChange'] : false;

        if (!isEmpty(body['name']) && !isEmpty(body['tel']) && !isEmpty(body['password'])) {
            body['code'] = isEmpty(body['code']) ? null : body['code']
            body['name'] = isEmpty(body['name']) ? null : body['name']
            body['tel'] = isEmpty(body['tel']) ? null : body['tel']
            body['password'] = isEmpty(body['password']) || !isPasswordChange ? null : body['password']
            body['address'] = isEmpty(body['address']) ? null : body['address']
            body['privilege'] = isEmpty(body['privilege']) ? 2 : body['privilege']

            let res = await api_employee(operation, body, key_id);
            if (res === 1) {
                setOpen(false);
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { init(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }
    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_employee('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setEmployee(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='employee' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='employee' placeholder={placeholder_txt} columns={columns} data={[...data]} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='40%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <EmployeeDetail employee={{...employee}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default Employee;