import React, { useEffect, useState } from 'react';
import CommonDetail from '../../common/common-detail';
import CommonTableList from '../../common/common-table-list';
import ConfigSeparateDetail from './config-separate-detail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnsType } from 'antd/es/table';
import { api_config_separate } from '../../../api/config';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { configSeparate, initConfigSeparate } from '../../../model/config';
import { Modal } from 'antd';
import { isEmpty } from '../../../utils/util';

const ConfigSeparate: React.FC = (prop) => {
    
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [configSeparate, setConfigSeparate] = useState<configSeparate>(initConfigSeparate);

    const [isSaveDetail, setIsSaveDetail] = useState(false);

    const filterField: any = ['create_date', 'from_product_code', 'from_product_name', 'to_product_code', 'to_product_name'];

    const placeholder_txt: string = 'วันที่ / รหัสสินค้าต้นทาง / สินค้าต้นทาง / รหัสสินค้าปลายทาง / สินค้าปลายทาง' 

    const columns: ColumnsType<any> = [
        { title: 'วันที่', dataIndex: 'create_date', key: 'create_date', width: '200px', },
        { title: 'สินค้าต้นทาง', dataIndex: 'from_product_name', key: 'from_product_name' },
        { title: 'จำนวนสินค้าต้นทาง', dataIndex: 'from_quantity', key: 'from_quantity', render: (_, record) =>
            data.length >= 1 ? ( 
                <label>{record.from_quantity} {record.from_product_unit_name}</label>
            ) : null
        },
        { title: 'สินค้าปลายทาง', dataIndex: 'to_product_name', key: 'to_product_name' },
        { title: 'จำนวนสินค้าปลายทาง', dataIndex: 'to_quantity', key: 'to_quantity', render: (_, record) =>
            data.length >= 1 ? ( 
                <label>{record.to_quantity} {record.to_product_unit_name}</label>
            ) : null
        },
        { title: '', dataIndex: 'operation', width: '160px', fixed: 'right', render: (_, record) =>
            data.length >= 1 ? ( 
                <div className='iconOperation'>
                    <FontAwesomeIcon icon="eye" onClick={() => { onShowDetail('VIEW', record) }} />
                    <FontAwesomeIcon icon="copy" onClick={() => { onShowDetail('COPY'); }} />
                    <FontAwesomeIcon icon="pen-to-square" onClick={() => { onShowDetail('EDIT', record); }} />
                    <FontAwesomeIcon icon="trash-can" onClick={() => { onDelete(record.id) }} />
                </div>
            ) : null
        }
    ]

    const init = async () => {
        setData([]);
        let getConfigSeparate = await api_config_separate('LIST', {});
        setData(getConfigSeparate);
        setIsSaveDetail(false);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
        }
    }

    const onShowDetail = (mode: string, configSeparateData?: configSeparate) => {
        if (mode === 'ADD' || mode === 'COPY') {
            setTitle('ตั้งค่าการแยกแพค')
        } else if (mode === 'EDIT') {
            setTitle('แก้ไขตั้งค่าการแยกแพค')    
        } else if (mode === 'VIEW') {
            setTitle('รายละเอียดตั้งค่าการแยกแพค')
        }

        setMode(mode);
        setIsSaveDetail(false);
        setConfigSeparate(configSeparateData !== undefined && configSeparateData !== null ? configSeparateData : initConfigSeparate);
        setOpen(true);
    };

    const onCloseDetail = () => {
        setOpen(false);
        setIsSaveDetail(false);
    };

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(configSeparate));
        
        if (configSeparate.id > 0) {
            operation = 'UPDATE';
            key_id = configSeparate.id;
        }

        if (!isEmpty(body['from_product']) && body['from_product'] != 0 && !isEmpty(body['from_quantity']) && !isEmpty(body['to_product']) && body['to_product'] != 0 && !isEmpty(body['to_quantity'])) {
            body['from_product'] = isEmpty(body['from_product']) ? 0 : body['from_product']
            body['from_quantity'] = isEmpty(body['from_quantity']) ? 0 : body['from_quantity']
            body['to_product'] = isEmpty(body['to_product']) ? 0 : body['to_product']
            body['to_quantity'] = isEmpty(body['to_quantity']) ? 0 : body['to_quantity']

            let res = await api_config_separate(operation, body, key_id);
            if (res === 1) {
                setIsSaveDetail(false);
                if (body['is_continue'] != 1) {
                    setOpen(false);
                }
                Modal.success({
                    ...getConfigModal('สำเร็จ', operation === 'UPDATE' ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { 
                        init(); 
                        if (body['is_continue'] == 1) {
                            setConfigSeparate(initConfigSeparate);
                            setIsSaveDetail(true);
                        }
                    }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', operation === 'UPDATE' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        }
    }

    const onDelete = async (key_id: number) => {
        Modal.confirm({
            ...getConfigModal('แจ้งเตือน', 'คุณต้องการลบข้อมูลนี้หรือไม่ ?'),
            ...{
                async onOk() { 
                    let res = await api_config_separate('DELETE', {}, key_id);
                    if (res === 1) {
                        Modal.success({
                            ...getConfigModal('สำเร็จ', 'ลบข้อมูลสำเร็จแล้ว'),
                            ...{ onOk() { init(); }}
                        });
                    } else {
                        Modal.error({
                            ...getConfigModal('ผิดพลาด', 'ลบข้อมูลไม่สำเร็จ'),
                        });
                    }
                }
            }
        });
    }

    const onDataDetailChange = (payload: any) => {
        setConfigSeparate(payload)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {/* { data.length > 0 ? <CommonTableList page='good-receive' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} /> : null } */}
            <CommonTableList page='config-separate' placeholder={placeholder_txt} columns={columns} data={data} filterField={filterField} onAdd={() => { onShowDetail('ADD') }} />
            <CommonDetail width='70%' mode={mode} title={title} open={open} onClosed={onCloseDetail} onSaved={onSave} element={ <ConfigSeparateDetail onSaved={isSaveDetail} mode={mode} configSeparate={{...configSeparate}} onChange={onDataDetailChange} /> } />
        </>
    );
}

export default ConfigSeparate;