import React, { useEffect, useState } from 'react';
import { Button, Drawer, Space } from 'antd';

interface props {
    mode: string,
    open: boolean,
    title: string,
    width: string,
    element?: React.ReactNode | null,
    onSaved: () => void,
    onClosed: () => void,
    // onPrinted: () => void
}

const CommonDetail: React.FC<props> = (prop) => {

    const [open, setOpen] = useState(false);

    const onShow = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        prop.onClosed();
    };

    const onSave = () => {
        prop.onSaved();
    }

    const onPrint = () => {
        setOpen(false);
        // prop.onPrinted();
    }

    const isShowButton = (btn: string) => {
        if (btn === 'ADD') {
            return (prop.mode === 'ADD' || prop.mode === 'EDIT' || prop.mode === 'MULTI')
        } else if (btn === 'PRINT') {
            return (prop.mode === 'EDIT' || prop.mode === 'VIEW')
        } else if (btn === 'BACK') {
            return (prop.mode === 'VIEW' || prop.mode === 'VIEW_ONLY')
        } else if (btn === 'CANCEL') {
            return (prop.mode === 'ADD' || prop.mode === 'EDIT' || prop.mode === 'MULTI')
        }

        return false;
    }

    useEffect(() => {
        if (prop.open) {
            onShow();
        } else {
            setOpen(false);
        }
    }, [prop.open])

    return (
        <>
            <Drawer 
                title={prop.title}
                width={prop.width} 
                placement="right" 
                maskClosable={false}
                destroyOnClose={true} 
                closable={false} 
                onClose={onClose} 
                open={open}
                footer={
                    <Space>
                        { isShowButton('ADD') ? <Button className='btn-save' onClick={onSave}>บันทึก</Button> : null }
                        { isShowButton('PRINT') ? <Button className='btn-common' onClick={onPrint}>Print</Button> : null }
                        { isShowButton('BACK') ? <Button className='btn-back' onClick={onClose}>ย้อนกลับ</Button> : null }
                        { isShowButton('CANCEL') ? <Button className='btn-cancel' onClick={onClose}>ยกเลิก</Button> : null }
                    </Space>
                }
            >
                {prop.element}
            </Drawer>
        </>
    );
}

export default CommonDetail;