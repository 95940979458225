
export interface device {
    key: number,
    id: number,
    name: string,
    device: string,
}

export const initDevice = {
    key: 1,
    id: 0,
    name: "",
    device: "",
}