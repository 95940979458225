import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { initWorkShift } from '../../model/sale';
import { getToken, isEmpty } from '../../utils/util';
import { api_work_shift } from '../../api/sale';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { decode as base64_decode } from 'js-base64';

interface props {
    lock: boolean,
    onSaved: () => void,
    onClosed: () => void,
}

const WorkShift: React.FC<props> = (prop) => {

    const [data, setData] = useState({});
    const [title, setTitle] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const [e1000, setE1000] = useState(0);
    const [e500, setE500] = useState(0);
    const [e100, setE100] = useState(0);
    const [e50, setE50] = useState(0);
    const [e20, setE20] = useState(0);
    const [e10, setE10] = useState(0);
    const [e5, setE5] = useState(0);
    const [e2, setE2] = useState(0);
    const [e1, setE1] = useState(0);
    const [e050, setE050] = useState(0);
    const [e025, setE025] = useState(0);

    const [c1000, setC1000] = useState(0);
    const [c500, setC500] = useState(0);
    const [c100, setC100] = useState(0);
    const [c50, setC50] = useState(0);
    const [c20, setC20] = useState(0);
    const [c10, setC10] = useState(0);
    const [c5, setC5] = useState(0);
    const [c2, setC2] = useState(0);
    const [c1, setC1] = useState(0);
    const [c050, setC050] = useState(0);
    const [c025, setC025] = useState(0);

    const [expectedTotal, setExpectedTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const init = async () => {
        let token = '';
        await getToken().then((item: string) => {
            token = item;
        });
        let getWorkShift: any = await api_work_shift('GET_BY_CONDITION', {'device': token});
        setTitle('เปิดกะ');
        setIsOpen(true);
        setData(JSON.parse(JSON.stringify(initWorkShift)));
        setInputData(initWorkShift);

        if (getWorkShift != null && getWorkShift != undefined) {
            if (getWorkShift['id'] > 0) {
                if (getWorkShift['status'] == 1) {
                    setTitle('ปิดกะ');
                    setIsOpen(false);
                }
                
                let exp_data = JSON.parse(JSON.stringify(initWorkShift));
                exp_data['e1000'] = getWorkShift.c1000;
                exp_data['e500'] = getWorkShift.c500;
                exp_data['e100'] = getWorkShift.c100;
                exp_data['e50'] = getWorkShift.c50;
                exp_data['e20'] = getWorkShift.c20;
                exp_data['e10'] = getWorkShift.c10;
                exp_data['e5'] = getWorkShift.c5;
                exp_data['e2'] = getWorkShift.c2;
                exp_data['e1'] = getWorkShift.c1;
                exp_data['e050'] = getWorkShift.c050;
                exp_data['e025'] = getWorkShift.c025;
                setData(JSON.parse(JSON.stringify(exp_data)));
                setInputData(exp_data);
            }
        }
    }

    const setInputData = (object: any) => {
        setE1000(object.e1000);
        setE500(object.e500);
        setE100(object.e100);
        setE50(object.e50);
        setE20(object.e20);
        setE10(object.e10);
        setE5(object.e5);
        setE2(object.e2);
        setE1(object.e1);
        setE050(object.e050);
        setE025(object.e025);

        setC1000(object.c1000);
        setC500(object.c500);
        setC100(object.c100);
        setC50(object.c50);
        setC20(object.c20);
        setC10(object.c10);
        setC5(object.c5);
        setC2(object.c2);
        setC1(object.c1);
        setC050(object.c050);
        setC025(object.c025);

        calulateTotal(object);
    }
    
    const onDataChange = (e: any) => {
        let key: string = String(e.target.name);
        let value: any = (
            e.target.type === 'checkbox'
                ? (e.target.checked ? 1 : 0)
                : e.target.type === 'radio' ? (e.target.value ? 1 : 0)
                : typeof(e.target.value) === 'number' ? Number(e.target.value)
                : String(e.target.value)
        );
        let object: any = data;
        object[key] = value;

        setData(object);
        setInputData(object);
    }

    const generate_amount = (key: string, amt: any) => {
        let count = 0;
        if (key == '025' || key == '050') {
            count = (parseInt(key) * (isEmpty(amt) ? 0 : parseFloat(amt))) / 100;
        } else {
            count = parseInt(key) * (isEmpty(amt) ? 0 : parseFloat(amt));
        }
        return count;
    }

    const calulateTotal = (item: any) => {
        let expected = 0;
        let total_count = 0;

        Object.keys(item).forEach((key: any) => {
            if (/[e][0-9]$/.test(key.substring(0, 2))) {
                expected = expected + generate_amount(key.substring(1), item[key]);
            } else if (/[c][0-9]$/.test(key.substring(0, 2))) {
                total_count = total_count + generate_amount(key.substring(1), item[key]);
            }
        });

        setExpectedTotal(expected);
        setTotal(total_count);
    }

    const getConfigModal = (titile: string, content: string) => {
        return {
            title: titile,
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: content,
            okText: 'ตกลง',
            cancelText: 'ยกเลิก',
            centered: true
        }
    }

    const onSave = async () => {
        let operation = 'INSERT';
        let key_id = undefined;
        let body = JSON.parse(JSON.stringify(data));
        let status = true;

        Object.keys(body).forEach((key: any) => {
            if (key != 'key' && key != 'id' && key != 'device') {
                if (isEmpty(body[key]) || body[key] < 0) {
                    status = false;
                    return false;
                }
            }
        });

        if (status && total > 0) {
            let uuid = isEmpty(window.localStorage.getItem("uuid")) ? "" : window.localStorage.getItem("uuid") + "";
            let user = JSON.parse(base64_decode(uuid));
            let token = '';
            await getToken().then((item: string) => {
                token = item;
            });
            body['device'] = token;
            body['create_by'] = user['id'];
            body['status'] = isOpen;
            body['expected'] = expectedTotal;
            body['total'] = total;

            let res = await api_work_shift(operation, body, key_id);
            if (res === 1) {
                Modal.success({
                    ...getConfigModal('สำเร็จ', 'เพิ่มข้อมูลสำเร็จแล้ว'),
                    ...{ onOk() { prop.onSaved(); }}
                });
            } else {
                Modal.error({
                    ...getConfigModal('ผิดพลาด', 'เพิ่มข้อมูลไม่สำเร็จ'),
                });
            }
        } else {
            Modal.warning({
                ...getConfigModal('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ครบถ้วน')
            });
        } 
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <div className='workShiftContainer'>
                <label>{title}</label>
                {/* <Checkbox onChange={(e: CheckboxChangeEvent) => {setNewFlag(e.target.checked)}}>เริ่มต้นใหม่</Checkbox> */}
                <table>
                    <tbody>
                        <tr>
                            <td>ประเภท</td>
                            <td>เงินในลิ้นชัก</td>
                            <td>จำนวนที่นับจริง</td>
                        </tr>
                        <tr>
                            <td>แบงค์ 1000</td>
                            <td><Input name="e1000" value={e1000} disabled /></td>
                            <td><Input name="c1000" value={c1000} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>แบงค์ 500</td>
                            <td><Input name="e500" value={e500} disabled /></td>
                            <td><Input name="c500" value={c500} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>แบงค์ 100</td>
                            <td><Input name="e100" value={e100} disabled /></td>
                            <td><Input name="c100" value={c100} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>แบงค์ 50</td>
                            <td><Input name="e50" value={e50} disabled /></td>
                            <td><Input name="c50" value={c50} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>แบงค์ 20</td>
                            <td><Input name="e20" value={e20} disabled /></td>
                            <td><Input name="c20" value={c20} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 10</td>
                            <td><Input name="e10" value={e10} disabled /></td>
                            <td><Input name="c10" value={c10} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 5</td>
                            <td><Input name="e5" value={e5} disabled /></td>
                            <td><Input name="c5" value={c5} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 2</td>
                            <td><Input name="e2" value={e2} disabled /></td>
                            <td><Input name="c2" value={c2} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 1</td>
                            <td><Input name="e1" value={e1} disabled /></td>
                            <td><Input name="c1" value={c1} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 0.50</td>
                            <td><Input name="e050" value={e050} disabled /></td>
                            <td><Input name="c050" value={c050} onChange={onDataChange} /></td>
                        </tr>
                        <tr>
                            <td>เหรียญ 0.25</td>
                            <td><Input name="e025" value={e025} disabled /></td>
                            <td><Input name="c025" value={c025} onChange={onDataChange} /></td>
                        </tr>
                    </tbody>
                </table>

                <div className='workShiftContainerTotal'>
                    <div>
                        <label>จำนวนเงินที่มีอยู่</label>
                        <Input name="expectedTotal" value={expectedTotal} disabled />
                    </div>
                    <div>
                        <label>จำนวนเงินที่นับจริง</label>
                        <Input name="total" value={total} disabled />
                    </div>
                </div>

                <div className='workShiftContainerTool'>
                    <Button className='btn-save' onClick={onSave}>ตกลง</Button>
                    { !prop.lock ? <Button className='btn-cancel' onClick={() => {prop.onClosed()}}>ยกเลิก</Button> : null}
                </div>
            </div>
        </>
    )
}

export default WorkShift;